export const ACCESS_TYPE_LIBERO = 1;
export const ACCESS_TYPE_FORZATO = 2;
export const LEARNING_TYPE_SOLO_AUTONOMO = 1;
export const LEARNING_TYPE_SOLO_CORSI = 2;
export const LEARNING_TYPE_AUTONOMI_CORSI = 3;
export const ROLE_STUDENT = "studente";
export const ROLE_TEACHER = "docente";
export const ROLE_ADMIN = "admin";
export const ROLE_NONE = "none";
export const LEARNING_SYSTEM_MOODLE = 1;

export const settori = [
  {
    value: "AGR/04",
    label: "Orticoltura e floricoltura",
  },
  {
    value: "AGR/06",
    label: "Tecnologia del legno e utilizzazioni forestali",
  },
  {
    value: "AGR/05",
    label: "Assestamento forestale e selvicoltura",
  },
  {
    value: "AGR/11",
    label: "Entomologia generale e applicata",
  },
  {
    value: "AGR/13",
    label: "Chimica agraria",
  },
  {
    value: "AGR/14",
    label: "Pedologia",
  },
  {
    value: "AGR/16",
    label: "Microbiologia agraria",
  },
  {
    value: "AGR/18",
    label: "Nutrizione e alimentazione animale",
  },
  {
    value: "AGR/19",
    label: "Zootecnica speciale",
  },
  {
    value: "BIO/04",
    label: "Fisiologia vegetale",
  },
  {
    value: "CHIM/03",
    label: "Chimica generale e inorganica",
  },
  {
    value: "CHIM/10",
    label: "Chimica degli alimenti",
  },
  {
    value: "FIS/05",
    label: "Astronomia e astrofisica",
  },
  {
    value: "GEO/01",
    label: "Paleontologia e paleoecologia",
  },
  {
    value: "GEO/02",
    label: "Geologia stratigrafica e sedimentologica",
  },
  {
    value: "GEO/12",
    label: "Oceanografia e fisica dell'atmosfera",
  },
  {
    value: "ICAR/03",
    label: "Ingegneria sanitaria - ambientale",
  },
  {
    value: "ICAR/13",
    label: "Disegno industriale",
  },
  {
    value: "ICAR/14",
    label: "Composizione architettonica e urbana",
  },
  {
    value: "ICAR/20",
    label: "Tecnica e pianificazione urbanistica",
  },
  {
    value: "ING-IND/05",
    label: "Impianti e sistemi aerospaziali",
  },
  {
    value: "ING-IND/12",
    label: "Misure meccaniche e termiche",
  },
  {
    value: "ING-IND/13",
    label: "Meccanica applicata alle macchine",
  },
  {
    value: "ING-IND/15",
    label: "Disegno e metodi dell'ingegneria industriale",
  },
  {
    value: "ING-IND/18",
    label: "Fisica dei reattori nucleari",
  },
  {
    value: "ING-IND/26",
    label: "Teoria dello sviluppo dei processi chimici",
  },
  {
    value: "ING-IND/27",
    label: "Chimica industriale e tecnologica",
  },
  {
    value: "ING-IND/28",
    label: "Ingegneria e sicurezza degli scavi",
  },
  {
    value: "ING-IND/30",
    label: "Idrocarburi e fluidi del sottosuolo",
  },
  {
    value: "ING-IND/33",
    label: "Sistemi elettrici per l'energia",
  },
  {
    value: "ING-IND/35",
    label: "Ingegneria economico-gestionale",
  },
  {
    value: "ING-INF/02",
    label: "Campi elettromagnetici",
  },
  {
    value: "IUS/04",
    label: "Diritto commerciale",
  },
  {
    value: "L-ANT/01",
    label: "Preistoria e protostoria",
  },
  {
    value: "L-ANT/05",
    label: "Papirologia",
  },
  {
    value: "L-ANT/06",
    label: "Etruscologia e antichita' italiche",
  },
  {
    value: "L-ANT/09",
    label: "Topografia antica",
  },
  {
    value: "L-ART/02",
    label: "Storia dell'arte moderna",
  },
  {
    value: "L-FIL-LET/02",
    label: "Lingua e letteratura greca",
  },
  {
    value: "L-FIL-LET/03",
    label: "Filologia italica, illirica, celtica",
  },
  {
    value: "L-FIL-LET/08",
    label: "Letteratura latina medievale e umanistica",
  },
  {
    value: "L-FIL-LET/13",
    label: "Filologia della letteratura italiana",
  },
  {
    value: "L-LIN/06",
    label: "Lingua e letterature ispano-americane",
  },
  {
    value: "L-LIN/15",
    label: "Lingue e letterature nordiche",
  },
  {
    value: "L-OR/02",
    label: "Egittologia e civilta' copta",
  },
  {
    value: "L-OR/07",
    label: "Semitistica-lingue e letterature dell'Etiopia",
  },
  {
    value: "L-OR/09",
    label: "Lingue e letterature dell'Africa",
  },
  {
    value: "L-OR/10",
    label: "Storia dei paesi islamici",
  },
  {
    value: "L-OR/16",
    label: "Archeologia e storia dell'arte dell'India e dell'Asia centrale",
  },
  {
    value: "M-FIL/07",
    label: "Storia della filosofia antica",
  },
  {
    value: "M-PED/02",
    label: "Storia della pedagogia",
  },
  {
    value: "M-PED/04",
    label: "Pedagogia sperimentale",
  },
  {
    value: "M-PSI/02",
    label: "Psicobiologia e psicologia fisiologica",
  },
  {
    value: "M-PSI/04",
    label: "Psicologia dello sviluppo e psicologia dell'educazione",
  },
  {
    value: "M-STO/05",
    label: "Storia della scienza e delle tecniche",
  },
  {
    value: "MAT/04",
    label: "Matematiche complementari",
  },
  {
    value: "MAT/09",
    label: "Ricerca operativa",
  },
  {
    value: "MED/04",
    label: "Patologia generale",
  },
  {
    value: "MED/06",
    label: "Oncologia medica",
  },
  {
    value: "MED/09",
    label: "Medicina interna",
  },
  {
    value: "MED/11",
    label: "Malattie dell'apparato cardiovascolare",
  },
  {
    value: "MED/14",
    label: "Nefrologia",
  },
  {
    value: "MED/21",
    label: "Chirurgia toracica",
  },
  {
    value: "MED/24",
    label: "Urologia",
  },
  {
    value: "MED/25",
    label: "Psichiatria",
  },
  {
    value: "MED/34",
    label: "Medicina fisica e riabilitativa",
  },
  {
    value: "AGR/02",
    label: "Agronomia e coltivazioni erbacee",
  },
  {
    value: "AGR/09",
    label: "Meccanica agraria",
  },
  {
    value: "BIO/02",
    label: "Botanica sistematica",
  },
  {
    value: "BIO/06",
    label: "Anatomia comparata e citologia",
  },
  {
    value: "BIO/08",
    label: "Antropologia",
  },
  {
    value: "BIO/16",
    label: "Anatomia umana",
  },
  {
    value: "BIO/17",
    label: "Istologia",
  },
  {
    value: "BIO/18",
    label: "Genetica",
  },
  {
    value: "CHIM/02",
    label: "Chimica fisica",
  },
  {
    value: "CHIM/06",
    label: "Chimica organica",
  },
  {
    value: "CHIM/07",
    label: "Fondamenti chimici delle tecnologie",
  },
  {
    value: "CHIM/12",
    label: "Chimica dell'ambiente e dei beni culturali",
  },
  {
    value: "FIS/04",
    label: "Fisica nucleare e subnucleare",
  },
  {
    value: "FIS/06",
    label: "Fisica per il sistema terra e per il mezzo circumterrestre",
  },
  {
    value: "GEO/03",
    label: "Geologia strutturale",
  },
  {
    value: "GEO/10",
    label: "Geofisica della terra solida",
  },
  {
    value: "ICAR/02",
    label: "Costruzioni idrauliche e marittime e idrologia",
  },
  {
    value: "ICAR/04",
    label: "Strade, ferrovie e aeroporti",
  },
  {
    value: "ICAR/05",
    label: "Trasporti",
  },
  {
    value: "ICAR/06",
    label: "Topografia e cartografia",
  },
  {
    value: "ICAR/10",
    label: "Architettura tecnica",
  },
  {
    value: "ICAR/12",
    label: "Tecnologia dell'architettura",
  },
  {
    value: "ICAR/17",
    label: "Disegno",
  },
  {
    value: "ICAR/19",
    label: "Restauro",
  },
  {
    value: "ICAR/21",
    label: "Urbanistica",
  },
  {
    value: "INF/01",
    label: "Informatica",
  },
  {
    value: "ING-IND/02",
    label: "Costruzioni e impianti navali e marini",
  },
  {
    value: "ING-IND/03",
    label: "Meccanica del volo",
  },
  {
    value: "ING-IND/04",
    label: "Costruzioni e strutture aerospaziali",
  },
  {
    value: "ING-IND/06",
    label: "Fluidodinamica",
  },
  {
    value: "ING-IND/09",
    label: "Sistemi per l'energia e l'ambiente",
  },
  {
    value: "ING-IND/14",
    label: "Progettazione meccanica e costruzione di macchine",
  },
  {
    value: "ING-IND/17",
    label: "Impianti industriali meccanici",
  },
  {
    value: "ING-IND/21",
    label: "Metallurgia",
  },
  {
    value: "ING-IND/24",
    label: "Principi di ingegneria chimica",
  },
  {
    value: "ING-IND/31",
    label: "Elettrotecnica",
  },
  {
    value: "ING-IND/32",
    label: "Convertitori, macchine e azionamenti elettrici",
  },
  {
    value: "ING-INF/01",
    label: "Elettronica",
  },
  {
    value: "ING-INF/03",
    label: "Telecomunicazioni",
  },
  {
    value: "ING-INF/05",
    label: "Sistemi di elaborazione delle informazioni",
  },
  {
    value: "IUS/01",
    label: "Diritto privato",
  },
  {
    value: "IUS/08",
    label: "Diritto costituzionale",
  },
  {
    value: "IUS/10",
    label: "Diritto amministrativo",
  },
  {
    value: "IUS/12",
    label: "Diritto tributario",
  },
  {
    value: "IUS/15",
    label: "Diritto processuale civile",
  },
  {
    value: "IUS/16",
    label: "Diritto processuale penale",
  },
  {
    value: "IUS/17",
    label: "Diritto penale",
  },
  {
    value: "IUS/18",
    label: "Diritto romano e diritti dell'antichita'",
  },
  {
    value: "IUS/19",
    label: "Storia del diritto medievale e moderno",
  },
  {
    value: "IUS/20",
    label: "Filosofia del diritto",
  },
  {
    value: "IUS/21",
    label: "Diritto pubblico comparato",
  },
  {
    value: "L-ANT/03",
    label: "Storia romana",
  },
  {
    value: "L-ANT/07",
    label: "Archeologia classica",
  },
  {
    value: "L-ART/05",
    label: "Discipline dello spettacolo",
  },
  {
    value: "L-FIL-LET/04",
    label: "Lingua e letteratura latina",
  },
  {
    value: "L-FIL-LET/09",
    label: "Filologia e linguistica romanza",
  },
  {
    value: "L-FIL-LET/11",
    label: "Letteratura italiana contemporanea",
  },
  {
    value: "L-FIL-LET/12",
    label: "Linguistica italiana",
  },
  {
    value: "L-FIL-LET/15",
    label: "Filologia germanica",
  },
  {
    value: "L-LIN/01",
    label: "Glottologia e linguistica",
  },
  {
    value: "L-LIN/09",
    label: "Lingua e traduzione - lingue portoghese e brasiliana",
  },
  {
    value: "L-LIN/16",
    label: "Lingua e letteratura nederlandese",
  },
  {
    value: "L-LIN/18",
    label: "Lingua e letteratura albanese",
  },
  {
    value: "L-LIN/19",
    label: "Filologia ugro-finnica",
  },
  {
    value: "L-LIN/21",
    label: "Slavistica",
  },
  {
    value: "MED/36",
    label: "Diagnostica per immagini e radioterapia",
  },
  {
    value: "MED/39",
    label: "Neuropsichiatria infantile",
  },
  {
    value: "MED/44",
    label: "Medicina del lavoro",
  },
  {
    value: "MED/45",
    label: "Scienze infermieristiche generali, cliniche e pediatriche",
  },
  {
    value: "SECS-P/03",
    label: "Scienza delle finanze",
  },
  {
    value: "SECS-P/06",
    label: "Economia applicata",
  },
  {
    value: "SECS-P/10",
    label: "Organizzazione aziendale",
  },
  {
    value: "SECS-P/12",
    label: "Storia economica",
  },
  {
    value: "SECS-P/13",
    label: "Scienze merceologiche",
  },
  {
    value: "SECS-S/02",
    label: "Statistica per la ricerca sperimentale e tecnologica",
  },
  {
    value: "SPS/04",
    label: "Scienza politica",
  },
  {
    value: "SPS/12",
    label: "Sociologia giuridica, della devianza e mutamento sociale",
  },
  {
    value: "VET/04",
    label: "Ispezione degli alimenti di origine animale",
  },
  {
    value: "VET/06",
    label: "Parassitologia e malattie parassitarie degli animali",
  },
  {
    value: "VET/08",
    label: "Clinica medica veterinaria",
  },
  {
    value: "L-OR/01",
    label: "Storia del vicino oriente antico",
  },
  {
    value: "L-OR/08",
    label: "Ebraico",
  },
  {
    value: "L-OR/12",
    label: "Lingua e letteratura araba",
  },
  {
    value: "L-OR/20",
    label: "Archeologia, storia dell'arte e filosofie dell'Asia orientale",
  },
  {
    value: "M-DEA/01",
    label: "Discipline demoetnoantropologiche",
  },
  {
    value: "M-EDF/01",
    label: "Metodi e didattiche delle attivita' motorie",
  },
  {
    value: "M-EDF/02",
    label: "Metodi e didattiche delle attivita' sportive",
  },
  {
    value: "M-FIL/02",
    label: "Logica e filosofia della scienza",
  },
  {
    value: "M-FIL/05",
    label: "Filosofia e teoria dei linguaggi",
  },
  {
    value: "M-FIL/06",
    label: "Storia della filosofia",
  },
  {
    value: "M-GGR/01",
    label: "Geografia",
  },
  {
    value: "M-PED/03",
    label: "Didattica e pedagogia speciale",
  },
  {
    value: "M-PSI/03",
    label: "Psicometria",
  },
  {
    value: "M-PSI/06",
    label: "Psicologia del lavoro e delle organizzazioni",
  },
  {
    value: "M-STO/04",
    label: "Storia contemporanea",
  },
  {
    value: "M-STO/06",
    label: "Storia delle religioni",
  },
  {
    value: "MAT/02",
    label: "Algebra",
  },
  {
    value: "MAT/05",
    label: "Analisi matematica",
  },
  {
    value: "MAT/07",
    label: "Fisica matematica",
  },
  {
    value: "MED/01",
    label: "Statistica medica",
  },
  {
    value: "MED/03",
    label: "Genetica medica",
  },
  {
    value: "MED/10",
    label: "Malattie dell'apparato respiratorio",
  },
  {
    value: "MED/13",
    label: "Endocrinologia",
  },
  {
    value: "MED/17",
    label: "Malattie infettive",
  },
  {
    value: "MED/22",
    label: "Chirurgia vascolare",
  },
  {
    value: "MED/26",
    label: "Neurologia",
  },
  {
    value: "MED/27",
    label: "Neurochirurgia",
  },
  {
    value: "MED/30",
    label: "Malattie apparato visivo",
  },
  {
    value: "MED/31",
    label: "Otorinolaringoiatria",
  },
  {
    value: "MED/38",
    label: "Pediatria generale e specialistica",
  },
  {
    value: "MED/46",
    label: "Scienze tecniche di medicina e di laboratorio",
  },
  {
    value: "MED/48",
    label: "Scienze infermieristiche e tecniche neuro-psichiatriche e riabilitative",
  },
  {
    value: "MED/49",
    label: "Scienze tecniche dietetiche applicate",
  },
  {
    value: "SECS-P/05",
    label: "Econometria",
  },
  {
    value: "SECS-P/09",
    label: "Finanza aziendale",
  },
  {
    value: "SECS-S/06",
    label: "Metodi matematici dell'economia e delle scienze attuariali e finanziarie",
  },
  {
    value: "SPS/07",
    label: "Sociologia generale",
  },
  {
    value: "VET/01",
    label: "Anatomia degli animali domestici",
  },
  {
    value: "VET/03",
    label: "Patologia generale e anatomia patologica veterinaria",
  },
  {
    value: "AGR/01",
    label: "Economia ed estimo rurale",
  },
  {
    value: "AGR/03",
    label: "Arboricoltura generale e coltivazioni arboree",
  },
  {
    value: "AGR/07",
    label: "Genetica agraria",
  },
  {
    value: "AGR/15",
    label: "Scienze e tecnologie alimentari",
  },
  {
    value: "AGR/17",
    label: "Zootecnica generale e miglioramento genetico",
  },
  {
    value: "BIO/03",
    label: "Botanica ambientale e applicata",
  },
  {
    value: "BIO/07",
    label: "Ecologia",
  },
  {
    value: "BIO/12",
    label: "Biochimica clinica e biologia molecolare clinica",
  },
  {
    value: "BIO/15",
    label: "Biologia farmaceutica",
  },
  {
    value: "BIO/19",
    label: "Microbiologia generale",
  },
  {
    value: "CHIM/05",
    label: "Scienza e tecnologia dei materiali polimerici",
  },
  {
    value: "CHIM/08",
    label: "Chimica farmaceutica",
  },
  {
    value: "CHIM/11",
    label: "Chimica e biotecnologia delle fermentazioni",
  },
  {
    value: "FIS/03",
    label: "Fisica della materia",
  },
  {
    value: "FIS/07",
    label: "Fisica applicata (a beni culturali, ambientali, biologia e medicina)",
  },
  {
    value: "FIS/08",
    label: "Didattica e storia della fisica",
  },
  {
    value: "GEO/06",
    label: "Mineralogia",
  },
  {
    value: "GEO/07",
    label: "Petrologia e petrografia",
  },
  {
    value: "GEO/08",
    label: "Geochimica e vulcanologia",
  },
  {
    value: "GEO/09",
    label: "Georisorse minerarie e app. mineralogico-petrografiche...",
  },
  {
    value: "ICAR/08",
    label: "Scienza delle costruzioni",
  },
  {
    value: "ICAR/11",
    label: "Produzione edilizia",
  },
  {
    value: "ICAR/15",
    label: "Architettura del paesaggio",
  },
  {
    value: "ICAR/18",
    label: "Storia dell'architettura",
  },
  {
    value: "ICAR/22",
    label: "Estimo",
  },
  {
    value: "ING-IND/01",
    label: "Architettura navale",
  },
  {
    value: "ING-IND/08",
    label: "Macchine a fluido",
  },
  {
    value: "ING-IND/10",
    label: "Fisica tecnica industriale",
  },
  {
    value: "ING-IND/16",
    label: "Tecnologie e sistemi di lavorazione",
  },
  {
    value: "ING-IND/20",
    label: "Misure e strumentazione nucleari",
  },
  {
    value: "ING-IND/22",
    label: "Scienza e tecnologia dei materiali",
  },
  {
    value: "ING-IND/29",
    label: "Ingegneria delle materie prime",
  },
  {
    value: "ING-IND/34",
    label: "Bioingegneria industriale",
  },
  {
    value: "ING-INF/04",
    label: "Automatica",
  },
  {
    value: "ING-INF/06",
    label: "Bioingegneria elettronica e informatica",
  },
  {
    value: "IUS/02",
    label: "Diritto privato comparato",
  },
  {
    value: "IUS/03",
    label: "Diritto agrario",
  },
  {
    value: "IUS/09",
    label: "Istituzioni di diritto pubblico",
  },
  {
    value: "IUS/11",
    label: "Diritto canonico e diritto ecclesiastico",
  },
  {
    value: "IUS/14",
    label: "Diritto dell'unione europea",
  },
  {
    value: "L-ANT/02",
    label: "Storia greca",
  },
  {
    value: "L-ANT/10",
    label: "Metodologie della ricerca archeologica",
  },
  {
    value: "L-ART/01",
    label: "Storia dell'arte medievale",
  },
  {
    value: "L-ART/06",
    label: "Cinema, fotografia e televisione",
  },
  {
    value: "L-ART/07",
    label: "Musicologia e storia della musica",
  },
  {
    value: "L-FIL-LET/05",
    label: "Filologia classica",
  },
  {
    value: "L-FIL-LET/06",
    label: "Letteratura cristiana antica",
  },
  {
    value: "L-FIL-LET/10",
    label: "Letteratura italiana",
  },
  {
    value: "L-FIL-LET/14",
    label: "Critica letteraria e letterature comparate",
  },
  {
    value: "L-LIN/03",
    label: "Letteratura francese",
  },
  {
    value: "L-LIN/07",
    label: "Lingua e traduzione - lingua spagnola",
  },
  {
    value: "L-LIN/08",
    label: "Letterature portoghese e brasiliana",
  },
  {
    value: "L-LIN/10",
    label: "Letteratura inglese",
  },
  {
    value: "L-LIN/12",
    label: "Lingua e traduzione - lingua inglese",
  },
  {
    value: "L-LIN/13",
    label: "Letteratura tedesca",
  },
  {
    value: "L-LIN/14",
    label: "Lingua e traduzione - lingua tedesca",
  },
  {
    value: "L-LIN/17",
    label: "Lingua e letteratura romena",
  },
  {
    value: "L-LIN/20",
    label: "Lingua e letteratura neogreca",
  },
  {
    value: "L-OR/04",
    label: "Anatolistica",
  },
  {
    value: "L-OR/05",
    label: "Archeologia e storia dell'arte del vicino oriente antico",
  },
  {
    value: "L-OR/06",
    label: "Archeologia fenicio-punica",
  },
  {
    value: "L-OR/14",
    label: "Filologia, religioni e storia dell'Iran",
  },
  {
    value: "L-OR/17",
    label: "Filosofie, religioni e storia dell'India e dell'Asia centrale",
  },
  {
    value: "L-OR/21",
    label: "Lingue e Letterature della Cina e dell'Asia sud-orientale",
  },
  {
    value: "L-OR/22",
    label: "Lingue e letterature del Giappone e della Corea",
  },
  {
    value: "AGR/08",
    label: "Idraulica agraria e sistemazioni idraulico-forestali",
  },
  {
    value: "AGR/10",
    label: "Costruzioni rurali e territorio agroforestale",
  },
  {
    value: "AGR/12",
    label: "Patologia vegetale",
  },
  {
    value: "AGR/20",
    label: "Zoocolture",
  },
  {
    value: "BIO/01",
    label: "Botanica generale",
  },
  {
    value: "BIO/05",
    label: "Zoologia",
  },
  {
    value: "BIO/09",
    label: "Fisiologia",
  },
  {
    value: "BIO/10",
    label: "Biochimica",
  },
  {
    value: "BIO/11",
    label: "Biologia molecolare",
  },
  {
    value: "BIO/13",
    label: "Biologia applicata",
  },
  {
    value: "BIO/14",
    label: "Farmacologia",
  },
  {
    value: "CHIM/01",
    label: "Chimica analitica",
  },
  {
    value: "CHIM/04",
    label: "Chimica industriale",
  },
  {
    value: "CHIM/09",
    label: "Farmaceutico tecnologico applicativo",
  },
  {
    value: "FIS/01",
    label: "Fisica sperimentale",
  },
  {
    value: "FIS/02",
    label: "Fisica teorica, modelli e metodi matematici",
  },
  {
    value: "GEO/04",
    label: "Geografia fisica e geomorfologia",
  },
  {
    value: "GEO/05",
    label: "Geologia applicata",
  },
  {
    value: "GEO/11",
    label: "Geofisica applicata",
  },
  {
    value: "ICAR/01",
    label: "Idraulica",
  },
  {
    value: "ICAR/07",
    label: "Geotecnica",
  },
  {
    value: "ICAR/09",
    label: "Tecnica delle costruzioni",
  },
  {
    value: "ICAR/16",
    label: "Architettura degli interni e allestimento",
  },
  {
    value: "ING-IND/07",
    label: "Propulsione aerospaziale",
  },
  {
    value: "ING-IND/11",
    label: "Fisica tecnica ambientale",
  },
  {
    value: "ING-IND/19",
    label: "Impianti nucleari",
  },
  {
    value: "ING-IND/23",
    label: "Chimica fisica applicata",
  },
  {
    value: "ING-IND/25",
    label: "Impianti chimici",
  },
  {
    value: "ING-INF/07",
    label: "Misure elettriche ed elettroniche",
  },
  {
    value: "IUS/05",
    label: "Diritto dell'economia",
  },
  {
    value: "IUS/06",
    label: "Diritto della navigazione",
  },
  {
    value: "IUS/07",
    label: "Diritto del lavoro",
  },
  {
    value: "IUS/13",
    label: "Diritto internazionale",
  },
  {
    value: "L-ANT/04",
    label: "Numismatica",
  },
  {
    value: "L-ANT/08",
    label: "Archeologia cristiana e medievale",
  },
  {
    value: "L-ART/03",
    label: "Storia dell'arte contemporanea",
  },
  {
    value: "L-ART/04",
    label: "Museologia e critica artistica e del restauro",
  },
  {
    value: "L-ART/08",
    label: "Etnomusicologia",
  },
  {
    value: "L-FIL-LET/01",
    label: "Civilta' egee",
  },
  {
    value: "L-FIL-LET/07",
    label: "Civilta' bizantina",
  },
  {
    value: "L-LIN/02",
    label: "Didattica delle lingue moderne",
  },
  {
    value: "L-LIN/04",
    label: "Lingua e traduzione - lingua francese",
  },
  {
    value: "L-LIN/05",
    label: "Letteratura spagnola",
  },
  {
    value: "L-LIN/11",
    label: "Lingua e letterature anglo-americane",
  },
  {
    value: "L-OR/03",
    label: "Assiriologia",
  },
  {
    value: "L-OR/11",
    label: "Archeologia e storia dell'arte musulmana",
  },
  {
    value: "L-OR/13",
    label: "Armenistica, caucasologia, mongolistica e turcologia",
  },
  {
    value: "L-OR/15",
    label: "Lingua e letteratura persiana",
  },
  {
    value: "L-OR/18",
    label: "Indologia e tibetologia",
  },
  {
    value: "L-OR/19",
    label: "Lingue e Letterature moderne del subcontinente indiano",
  },
  {
    value: "L-OR/23",
    label: "Storia dell'Asia orientale e sud-orientale",
  },
  {
    value: "M-FIL/08",
    label: "Storia della filosofia medievale",
  },
  {
    value: "M-GGR/02",
    label: "Geografia economico-politica",
  },
  {
    value: "M-PSI/05",
    label: "Psicologia sociale",
  },
  {
    value: "M-STO/01",
    label: "Storia medievale",
  },
  {
    value: "M-STO/07",
    label: "Storia del cristianesimo e delle chiese",
  },
  {
    value: "M-STO/08",
    label: "Archivistica, bibliografia e biblioteconomia",
  },
  {
    value: "M-STO/09",
    label: "Paleografia",
  },
  {
    value: "MAT/08",
    label: "Analisi numerica",
  },
  {
    value: "MED/02",
    label: "Storia della medicina",
  },
  {
    value: "MED/07",
    label: "Microbiologia e microbiologia clinica",
  },
  {
    value: "MED/08",
    label: "Anatomia patologica",
  },
  {
    value: "MED/15",
    label: "Malattie del sangue",
  },
  {
    value: "MED/16",
    label: "Reumatologia",
  },
  {
    value: "M-FIL/01",
    label: "Filosofia teoretica",
  },
  {
    value: "M-FIL/03",
    label: "Filosofia morale",
  },
  {
    value: "M-FIL/04",
    label: "Estetica",
  },
  {
    value: "M-PED/01",
    label: "Pedagogia generale e sociale",
  },
  {
    value: "M-PSI/01",
    label: "Psicologia generale",
  },
  {
    value: "M-PSI/07",
    label: "Psicologia dinamica",
  },
  {
    value: "M-PSI/08",
    label: "Psicologia clinica",
  },
  {
    value: "M-STO/02",
    label: "Storia moderna",
  },
  {
    value: "M-STO/03",
    label: "Storia dell'Europa orientale",
  },
  {
    value: "MAT/01",
    label: "Logica matematica",
  },
  {
    value: "MAT/03",
    label: "Geometria",
  },
  {
    value: "MAT/06",
    label: "Probabilita' e statistica matematica",
  },
  {
    value: "MED/05",
    label: "Patologia clinica",
  },
  {
    value: "MED/12",
    label: "Gastroenterologia",
  },
  {
    value: "MED/18",
    label: "Chirurgia generale",
  },
  {
    value: "MED/19",
    label: "Chirurgia plastica",
  },
  {
    value: "MED/28",
    label: "Malattie odontostomatologiche",
  },
  {
    value: "MED/35",
    label: "Malattie cutanee e veneree",
  },
  {
    value: "MED/37",
    label: "Neuroradiologia",
  },
  {
    value: "MED/40",
    label: "Ginecologia e ostetricia",
  },
  {
    value: "MED/42",
    label: "Igiene generale e applicata",
  },
  {
    value: "MED/43",
    label: "Medicina legale",
  },
  {
    value: "MED/47",
    label: "Scienze infermieristiche ostetrico-ginecologiche",
  },
  {
    value: "SECS-P/02",
    label: "Politica economica",
  },
  {
    value: "SECS-P/07",
    label: "Economia aziendale",
  },
  {
    value: "SECS-P/11",
    label: "Economia degli intermediari finanziari",
  },
  {
    value: "SECS-S/01",
    label: "Statistica",
  },
  {
    value: "SECS-S/03",
    label: "Statistica economica",
  },
  {
    value: "SECS-S/04",
    label: "Demografia",
  },
  {
    value: "SPS/01",
    label: "Filosofia politica",
  },
  {
    value: "SPS/02",
    label: "Storia delle dottrine politiche",
  },
  {
    value: "SPS/03",
    label: "Storia delle istituzioni politiche",
  },
  {
    value: "SPS/05",
    label: "Storia e istituzioni delle Americhe",
  },
  {
    value: "SPS/10",
    label: "Sociologia dell'ambiente e del territorio",
  },
  {
    value: "SPS/11",
    label: "Sociologia dei fenomeni politici",
  },
  {
    value: "SPS/13",
    label: "Storia e istituzioni dell'Africa",
  },
  {
    value: "VET/02",
    label: "Fisiologia veterinaria",
  },
  {
    value: "VET/09",
    label: "Clinica chirurgica veterinaria",
  },
  {
    value: "VET/10",
    label: "Clinica ostetrica e ginecologia veterinaria",
  },
  {
    value: "MED/20",
    label: "Chirurgia pediatrica e infantile",
  },
  {
    value: "MED/23",
    label: "Chirurgia cardiaca",
  },
  {
    value: "MED/29",
    label: "Chirurgia maxillofacciale",
  },
  {
    value: "MED/32",
    label: "Audiologia",
  },
  {
    value: "MED/33",
    label: "Malattie apparato locomotore",
  },
  {
    value: "MED/41",
    label: "Anestesiologia",
  },
  {
    value: "MED/50",
    label: "Scienze tecniche mediche applicate",
  },
  {
    value: "SECS-P/01",
    label: "Economia politica",
  },
  {
    value: "SECS-P/04",
    label: "Storia del pensiero economico",
  },
  {
    value: "SECS-P/08",
    label: "Economia e gestione delle imprese",
  },
  {
    value: "SECS-S/05",
    label: "Statistica sociale",
  },
  {
    value: "SPS/06",
    label: "Storia delle relazioni internazionali",
  },
  {
    value: "SPS/08",
    label: "Sociologia dei processi culturali e comunicativi",
  },
  {
    value: "SPS/09",
    label: "Sociologia dei processi economici e del lavoro",
  },
  {
    value: "SPS/14",
    label: "Storia e istituzioni dell'Asia",
  },
  {
    value: "VET/05",
    label: "Malattie infettive degli animali domestici",
  },
  {
    value: "VET/07",
    label: "Farmacologia e tossicologia veterinaria",
  },
];
