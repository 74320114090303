import { useState, useMemo, useEffect } from "react";
import Icon from "./icon";
import { Link, useLocation } from "react-router-dom";
import AddressModal from "./address-modal";
import { useAdresses, useAddAddress } from "../hooks/useQueryCustom";

type Props = {
  onChange: (...event: any[]) => void;
  saveForm: () => void;
  getData: () => any;
};

const Address = ({ onChange, saveForm, getData }: Props) => {
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const { state, pathname } = useLocation();
  const { selectedAddressId } = state || { selectedAddressId: null };

  const { data: addresses } = useAdresses();
  const addAddress = useAddAddress();

  const selectedAddress = useMemo(() => {
    if (selectedAddressId && addresses) {
      return addresses.find((item: Address) => item.addressId === selectedAddressId);
    } else {
      return addresses?.find((item: Address) => item.isFavourite) || addresses?.[0] || undefined;
    }
  }, [addresses, selectedAddressId]);

  useEffect(() => {
    if (selectedAddress) onChange(selectedAddress.addressId);
  }, [selectedAddress, onChange]);

  const onNewAddressSubmit = (address: Address) => {
    console.log(address);
    addAddress.mutate({ address });
    setOpenPopup(false);
    //onChange(newAddress?.id);
    //setAddresses([newAddress]);
  };

  return (
    <div>
      {addresses && addresses.length > 0 ? (
        <div className="Address">
          <div className="address__panel">
            <div className="d-flex justify-content-between">
              <div className="form__label me-1">{selectedAddress?.name}</div>
              <Link
                className="address__link"
                to="/scelta_indirizzo"
                onClick={saveForm}
                state={{ pathname, formData: getData() }}
              >
                Cambia indirizzo
              </Link>
            </div>
            <div className="address__line">{selectedAddress?.fullName}</div>
            {selectedAddress?.near && (
              <div className="address__line">Presso {selectedAddress.near}</div>
            )}
            <div className="address__line">{selectedAddress?.street}</div>
            <div className="address__line">
              {selectedAddress?.cap}&nbsp;{selectedAddress?.city}
            </div>
          </div>
        </div>
      ) : (
        <div className="address">
          <div className="form__label mb-3">Indirizzo di consegna</div>
          <div className="address__panel">
            <div className="mb-3">
              Non sono presenti indirizzi di consegna, ti chiediamo di aggiungerlo
            </div>
            <button
              type="button"
              className="address__button button button--secondary mb-2"
              onClick={() => setOpenPopup(true)}
            >
              <Icon name="circle-plus" width="24px" height="24px" />
              <span>Aggiungi un nuovo indirizzo</span>
            </button>
          </div>
          <AddressModal
            show={openPopup}
            onHide={() => setOpenPopup(false)}
            onSubmit={onNewAddressSubmit}
            addressToEdit={null}
          />
        </div>
      )}
    </div>
  );
};

export default Address;
