import CardContainer from "../components/card-container";
import MenuPublic from "../components/menu-public";
import SearchWrapper from "../components/search-wrapper";
import Seo from "../components/seo";
import { useProducts } from "../hooks/useQueryCustom";

const Webinar = () => {
  const webinar = useProducts("getWebinars", "webinar", 6);

  return (
    <>
      <Seo title="Webinar" />
      <MenuPublic />
      <SearchWrapper type="webinar">
        <section className="section">
          {webinar && (
            <CardContainer result={webinar} type="webinar" label={"Webinar"} more={true} size={6} />
          )}
        </section>
      </SearchWrapper>
    </>
  );
};

export default Webinar;
