import { callApiQl } from "../utils/call-api";
import { gql } from "graphql-request";

const HEADER_CONTENT_ID = import.meta.env.VITE_HEADER_CONTENT_ID;
const FOOTER_CONTENT_ID = import.meta.env.VITE_FOOTER_CONTENT_ID;

export const getWebContent = async (operationName: string, structerId: number) => {
  const variables = { contentStructureId: structerId };

  const data = await callApiQl(
    gql`
        query ${operationName}($contentStructureId: Long!) {
          contentStructureStructuredContents(contentStructureId: $contentStructureId sort:"datePublished:desc"){
            items{
              title
              dateModified
              datePublished
              dateCreated
              contentFields{
                label
                name
                dataType
                contentFieldValue{
                  data,
                  image {
                    contentType
                    contentUrl,
                    description,
                    encodingFormat,
                    fileExtension,
                    id,
                    sizeInBytes,
                    title
                    }
                },
              }
            },
            lastPage
            page
            pageSize
            totalCount
          }
        }
      `,
    variables,
    false
  );
  return data;
};

export const getHeaderMenu = async (operationName: string) => {
  const variables = { contentStructureId: HEADER_CONTENT_ID };

  const data = await callApiQl(
    gql`
        query ${operationName}($contentStructureId: Long!) {
          structuredContent(structuredContentId: $contentStructureId){
            contentFields{
              nestedContentFields{
                label
                name
                contentFieldValue{
                  data
                }
              }
            }
          }
        }
      `,
    variables,
    false
  );
  return data;
};

export const getFooterMenu = async (operationName: string) => {
  const variables = { contentStructureId: FOOTER_CONTENT_ID };

  const data = await callApiQl(
    gql`
        query ${operationName}($contentStructureId: Long!) {
          navigationMenu(navigationMenuId: $contentStructureId){
            id
            name
            siteId
            navigationMenuItems{
              id
              url
              name
              type
              navigationMenuItems{
              id
              url
              name
              type
              }
            }
          }
        }
      `,
    variables,
    false
  );
  return data;
};
