import { forwardRef } from "react";
import classNames from "classnames";

type SpinnerProps = {
  display?: "inline" | "block";
  spacing?: "s" | "m" | "l";
  className?: string;
};

export const Spinner = forwardRef<HTMLDivElement, SpinnerProps>(
  ({ display = "inline", spacing = "s", className }: SpinnerProps, ref) => {
    return (
      <div
        ref={ref}
        className={classNames(
          "spinner",
          display === "block" && "d-flex flex-grow-1 justify-content-center",
          spacing === "m" && "py-3",
          spacing === "l" && "py-5",
          className
        )}
      >
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }
);
