import Icon from "./icon";

interface Props {
  data: Format;
}

const Session = ({ data }: Props) => {
  return (
    <div className="session d-flex">
      <div className="session__content">
        <h3 className="session__title">{data.title}</h3>
        <div className="session__duration">
          {data?.duration && (
            <>
              <span className="session__label">Durata</span>
              <span>{data.duration}</span>
            </>
          )}
        </div>
        <div className="session__prices">
          <span className="session__label">Prezzo</span>
          <span>{data.price}&euro;</span>
        </div>
      </div>
      <div className="session__cta d-flex flex-column justify-content-end flex-grow-1">
        <button className="button button--tertiary">
          <Icon className="icon" name="cart" width="29px" height="22px" />
          <span>Acquista</span>
        </button>
      </div>
    </div>
  );
};

export default Session;
