import { Fragment } from "react";
import { Link } from "react-router-dom";
import Card from "./card";
import placeholders from "../utils/placeholders";
import LoadingError from "../components/loading-error";

type Props = {
  result: any;
  label: string;
  type: string;
  path?: string;
  more?: boolean;
  size: number;
};

const CardContainer = ({ result, label, type, path, more, size = 4 }: Props) => {
  const {
    data,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isRefetching,
  } = result;

  return (
    <div className="card-container">
      <div className="container-md">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between">
              <div className="card-container__label">{label}</div>
              {path && (
                <Link className="button d-md-none" to={path}>
                  Visualizza tutti
                </Link>
              )}
            </div>
          </div>
        </div>
        {isError ? (
          <LoadingError container={false} className="error-block--page" />
        ) : (
          <div className="row">
            {data?.pages.map((group: any, i: number) => (
              <Fragment key={i}>
                {group.pearsonProducts.items.map((product: Product, j: number) => (
                  <div key={j} className="col-12 col-md-6">
                    <Card data={product} type={type} />
                  </div>
                ))}
              </Fragment>
            ))}
          </div>
        )}

        {isFetching && !isRefetching && (
          <div className="row">
            {placeholders(size).map((product: Product | Placeholder, i: number) => (
              <div key={i} className="col-12 col-md-6">
                <Card data={product} type={type} />
              </div>
            ))}
          </div>
        )}

        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-end mt-2">
              {path && !more && (
                <Link className="button d-none d-md-inline-block" to={path}>
                  Visualizza tutti
                </Link>
              )}
            </div>
          </div>
        </div>

        {more && hasNextPage && !isFetchingNextPage && (
          <div className="row">
            <div className="col-12 text-center mt-4 mt-md-1 mb-5">
              <button onClick={() => fetchNextPage()} className="button button--secondary">
                Carica di più
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default CardContainer;
