import Backlink from "../components/backlink";
import LoadingError from "../components/loading-error";
import { useState, Fragment, useContext } from "react";
import UserContext from "../context/user";
import Icon from "../components/icon";
import Tabs from "../components/tabs";
import dayjs from "dayjs";
import CustomModal from "../components/modal";
import { useNavigate, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useProduct } from "../hooks/useQueryCustom";
import MenuPublic from "../components/menu-public";
import { ROLE_TEACHER } from "../utils/constants";
import Seo from "../components/seo";
import { Helmet } from "react-helmet-async";

const ProductDetail = () => {
  const [openAuthorPopup, setOpenAuthorPopup] = useState<boolean>(false);
  const [openProfessorPopup, setOpenProfessorPopup] = useState<boolean>(false);
  const [author, setAuthor] = useState<Author | null>(null);
  const { productId } = useParams<{ productId: string }>();

  const { user, login } = useContext(UserContext);
  const navigate = useNavigate();
  const { data, isError } = useProduct("getProductByProductId", productId as string);

  const richiediCopia = () => {
    if (user && user.role === ROLE_TEACHER) {
      navigate(`/richiesta_copia/${productId}`);
    } else {
      setOpenProfessorPopup(true);
    }
  };

  const gotoLogin = () => {
    login();
  };

  return isError ? (
    <LoadingError className="error-block--page" />
  ) : (
    <>
      {productId?.length === 13 && (
        <Helmet>
          <link rel="canonical" href={`${window.location.origin}/catalogo/${data?.productId}`} />
        </Helmet>
      )}
      <MenuPublic className="header-menu--border" />
      <div className="page-detail">
        <div className="container-md">
          <div className="row">
            <div className="col-md-12 ">
              <Backlink path="catalogo" anchor="Vedi tutti i prodotti" />
              <div className="d-flex flex-column flex-md-row align-items-start">
                <div className="page-detail__cover-image d-flex mb-3">
                  {data?.coverImage ? (
                    <img
                      src={data?.coverImage}
                      alt={`Copertina del prodotto ${data.productName}`}
                    />
                  ) : (
                    <Skeleton width="232px" height="289px" />
                  )}
                </div>
                <div className="page-detail__content flex-grow-1">
                  {data ? (
                    <>
                      <Seo title={data?.productName} />
                      <h1 className="page-detail__title">{data?.productName}</h1>
                      <h2 className="page-detail__subtitle">{data?.productSubtitle}</h2>
                      <div className="page-detail__edition">{data?.descriptionEdition}</div>
                      <div className="page-detail__author">
                        {data?.author.map((author: Author, index: number) => (
                          <Fragment key={index}>
                            {author.authorBio ? (
                              <button
                                onClick={() => {
                                  setAuthor(author);
                                  setOpenAuthorPopup(true);
                                }}
                              >
                                {author.authorName} {author.authorSurname}
                              </button>
                            ) : (
                              <span>
                                {author.authorName} {author.authorSurname}
                              </span>
                            )}
                            {index < data.author.length - 1 ? <span> - </span> : ""}
                          </Fragment>
                        ))}
                      </div>
                      {data?.productAbstract && (
                        <div
                          className="page-detail__abstract"
                          dangerouslySetInnerHTML={{
                            __html: data.productAbstract,
                          }}
                        ></div>
                      )}
                      {data?.subjectCategoryName && (
                        <div className="page-detail__subject">
                          <span className="page-detail__label">Materia</span>
                          <span>{data?.subjectCategoryName}</span>
                        </div>
                      )}
                      <div className="page-detail__year">
                        <span className="page-detail__label">Anno</span>
                        <span>{dayjs(data?.publishDate).format("YYYY")}</span>
                      </div>
                      {(!user || user?.role === ROLE_TEACHER) && (
                        <button onClick={() => richiediCopia()} className="button">
                          <Icon name="essay" width="20px" height="20px" />
                          <span>Richiesta Copia Saggio Docente</span>
                        </button>
                      )}
                      <Tabs
                        formats={data?.formats}
                        description={data?.productDescription}
                        index={data?.index}
                        resources={data?.resources}
                      />
                    </>
                  ) : (
                    <>
                      <Skeleton count={5.5} />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          show={openAuthorPopup}
          headerText={`${author?.authorName} ${author?.authorSurname}`}
          type="alert"
          scrollable={false}
          onHide={() => setOpenAuthorPopup(false)}
        >
          <div dangerouslySetInnerHTML={{ __html: author ? author?.authorBio : "" }} />
        </CustomModal>
        <CustomModal
          show={openProfessorPopup}
          headerText="Attenzione"
          type="confirm"
          confirmButtonText="Login / Registrati"
          scrollable={false}
          onConfirm={gotoLogin}
          onHide={() => setOpenProfessorPopup(false)}
        >
          <div>
            Per richiedere una copia saggio è necessario effettuare il login ed essere docenti
            universitari.
          </div>
        </CustomModal>
      </div>
    </>
  );
};

export default ProductDetail;
