import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import { useAdresses, useDeleteAddress } from "../hooks/useQueryCustom";
import { useAddAddress, useUpdateAddress } from "../hooks/useQueryCustom";

import Icon from "../components/icon";
import AddressModal from "../components/address-modal";
import AddressItem from "../components/addressItem";
import { toast } from "react-toastify";

const AddressSelect = () => {
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [addressToEdit, setAddressToEdit] = useState<Address | null>(null);
  const [selectedAddressId, setSelectedAddressId] = useState<number | null>(null);
  const { pathname, formData } = useLocation().state;

  const { data: addresses } = useAdresses();
  const addAddress = useAddAddress();
  const updateAddress = useUpdateAddress();
  const deleteAddress = useDeleteAddress();

  const preferred = useMemo(() => {
    const favourite = addresses?.find((item: Address) => item.isFavourite) || null;
    setSelectedAddressId(favourite?.addressId);
    return favourite;
  }, [addresses]);

  const others = useMemo(() => {
    return addresses?.filter((item: Address) => !item.isFavourite) || null;
  }, [addresses]);

  const editAddress = (address: Address) => {
    setAddressToEdit(address);
    setOpenPopup(true);
  };

  const onDeleteAddress = async (address: Address) => {
    if (address.addressId && !address.isRegistrationPhase) {
      setOpenPopup(false);
      await toast.promise(deleteAddress.mutateAsync({ address }), {
        pending: "Eliminazione in corso",
        success: "Operazione completata con successo",
        error: "Si è verificato un errore",
      });
    } else {
      toast.error("Non puoi eliminare questo indirizzo");
    }
  };

  const addNewAddress = () => {
    setAddressToEdit(null);
    setOpenPopup(true);
  };

  const { register } = useForm();

  const onAddressSubmit = async (address: Address) => {
    //console.log("onAddressSubmit", address);
    setOpenPopup(false);
    if (!address.addressId) {
      await toast.promise(addAddress.mutateAsync({ address }), {
        pending: "Inserimento in corrso",
        success: "Operazione completata con successo",
        error: "Si è verificato un errore",
      });
    } else {
      await toast.promise(updateAddress.mutateAsync({ address }), {
        pending: "Aggiornamento in corrso",
        success: "Operazione completata con successo",
        error: "Si è verificato un errore",
      });
    }
  };

  const onRadioClick = (id: number) => {
    setSelectedAddressId(id);
  };

  return (
    <div className="address-select">
      <div className="page__header">
        <div className="container-md">
          <div className="row">
            <div className="col-12 ">
              <div className="page__header-text d-flex flex-column justify-content-center">
                <h1 className="page__header-title">Indirizzo di consegna</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="container-md">
          <div className="row">
            <div className="col-12 ">
              <div className="row">
                {preferred ? (
                  <>
                    <div className="col-12">
                      <div className="address-select__subtitle mt-1 mb-3">
                        Indirizzo di consegna predefinito
                      </div>
                    </div>
                    <div className="col-12">
                      <AddressItem
                        key={preferred.addressId}
                        address={preferred}
                        index={0}
                        register={register}
                        editAddress={editAddress}
                        checked={selectedAddressId === preferred.addressId}
                        onClick={onRadioClick}
                      />
                    </div>
                  </>
                ) : null}

                {others && others.length > 0 ? (
                  <>
                    <div className="col-12">
                      <div className="address-select__subtitle mt-1 mb-3">
                        Altri indirizzi di consegna
                      </div>
                    </div>
                    <div className="col-12">
                      {others.map((address: Address, i: number) => (
                        <AddressItem
                          key={i}
                          address={address}
                          index={i}
                          checked={selectedAddressId === address.addressId}
                          register={register}
                          editAddress={editAddress}
                          onClick={onRadioClick}
                        />
                      ))}
                    </div>
                  </>
                ) : null}
              </div>
              <div className="d-flex flex-column flex-md-row justify-content-md-between">
                <button className="button button--secondary mb-3 mb-md-0" onClick={addNewAddress}>
                  <Icon name="circle-plus" width="24px" height="24px" />
                  <span>Aggiungi un nuovo indirizzo</span>
                </button>
                <Link
                  to={pathname}
                  state={{ selectedAddressId, formData }}
                  className="button button--tertiary mb-3 mb-md-0"
                >
                  <Icon name="map-marker" width="24px" height="24px" />
                  <span>Spedisci a questo indirizzo</span>
                </Link>
              </div>
              <AddressModal
                show={openPopup}
                onHide={() => setOpenPopup(false)}
                onSubmit={onAddressSubmit}
                onDelete={onDeleteAddress}
                addressToEdit={addressToEdit}
                key={openPopup as unknown as string}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressSelect;
