import { useForm } from "react-hook-form";
import { useEnrollCourse } from "../hooks/useQueryCustom";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { LEARNING_TYPE_AUTONOMI_CORSI } from "../utils/constants";
import { Link } from "react-router-dom";

type Props = {
  product: MyProduct;
  showEnrollCourse: boolean;
  onEnrollCourseCancel: () => void;
  handleBack: () => void;
};

const MyEnroll = ({ product, showEnrollCourse, onEnrollCourseCancel, handleBack }: Props) => {
  const { register, handleSubmit, reset } = useForm();
  const enrollCourse = useEnrollCourse();

  const onSubmit = async (data: any) => {
    const code = Number(data.code);
    if (code === product.courses[0].code) {
      toast.warning("Sei già iscritto a questo corso");
    } else {
      console.log("Form submitted", data);
      const productId = product?.product.productId as number;

      await toast.promise(enrollCourse.mutateAsync({ code, productId }), {
        pending: "Richiesta inoltrata",
        success: "Operazione completata con successo",
        error: {
          render({ data }: any) {
            if (data?.message === "course-does-not-exist") {
              return "Il codice corso inserito non esiste: conttatta il tuo docente";
            } else return "Si è verificato un errore";
          },
          autoClose: false,
        },
      });
      //handleBack();
    }
  };

  const onBackToAutonomo = async () => {
    const code = product.product.moodleId;
    const productId = product?.product.productId as number;

    await toast.promise(enrollCourse.mutateAsync({ code, productId }), {
      pending: "Richiesta inoltrata",
      success: "Operazione completata con successo",
      error: "Si è verificato un errore",
    });
  };

  const resetForm = () => {
    reset();
    onEnrollCourseCancel();
  };
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="my-courses-form">
            <form className="" onSubmit={handleSubmit(onSubmit)}>
              <h2 className="my-courses__title mb-3">Iscriviti al corso</h2>
              <strong className="d-inline-block mb-1">
                Inserisci il codice del corso comunicato dal tuo docente
              </strong>
              <div className="d-flex flex-column flex-md-row mt-1">
                <input
                  type="text"
                  aria-label="codice corso"
                  className="my-courses-form__input"
                  placeholder="es. EO1RMIP9"
                  {...register("code", { required: true })}
                />
                <div className="my-courses-form__actions">
                  <button type="submit" className="button button--tertiary">
                    Conferma
                  </button>
                  <button type="button" onClick={resetForm} className="button button--secondary">
                    Annulla
                  </button>
                </div>
              </div>
            </form>
            {product.courses?.[0] &&
              product.product.moodleId !== product.courses?.[0].code &&
              product.product.learningType === LEARNING_TYPE_AUTONOMI_CORSI && (
                <div className="mt-4">
                  <button type="button" onClick={onBackToAutonomo} className="mt-3 d-block button">
                    Torna allo studio autonomo
                  </button>
                </div>
              )}
          </div>
        </div>
      </div>

      {product.courses[0] && (
        <>
          <h2 className="my-courses__title my-3">Corso attuale</h2>
          <div className="my-courses__list">
            <div className="row">
              <div className="col-4">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="my-courses__label my-courses__label--link"
                  href={product.courses[0].url}
                >
                  {product.courses[0].name}
                </a>
                <div className="my-courses__date">
                  {dayjs(product.courses[0].createDate).locale("it").format("MMMM YYYY")}
                </div>
              </div>
              <div className="col-4">
                <div className="my-courses__label">Materia</div>
                <div>{product.product?.subjectCategoryName}</div>
              </div>
              <div className="col-4">
                <div className="my-courses__label">Codice corso</div>
                <span>{product.courses[0].code}</span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MyEnroll;
