import FooterMenu from "./footer-menu";
import { useState } from "react";
import { useFooterMenu } from "../hooks/useQueryCustom";

const FooterMenuContainer = () => {
  const { data: footerMenu } = useFooterMenu();

  const [open, setOpen] = useState(0);
  const handleClick = (id: number): void => {
    if (id === open) {
      setOpen(-1);
    } else {
      setOpen(id);
    }
  };
  return (
    <div className="footer-menu-container">
      <div className="container-md">
        <div className="row">
          {footerMenu?.navigationMenu?.navigationMenuItems.map((item: footerItem, i: number) => (
            <div key={i} className="col-12 col-md-6 col-lg-3">
              <FooterMenu
                className="footer-menu-container__item"
                title={item.name}
                items={item?.navigationMenuItems}
                open={open === i}
                handleClick={() => handleClick(i)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FooterMenuContainer;
