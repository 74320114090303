import Skeleton from "react-loading-skeleton";
import { Fragment } from "react";
import dayjs from "dayjs";

type Props = {
  myProduct: MyProduct | Placeholder;
};

const MyOthers = ({ myProduct }: Props) => {
  return (
    <div className="my-product d-flex">
      <div className="my-product__image">
        {"placeholder" in myProduct ? (
          <Skeleton height={180} width={130} />
        ) : (
          <>
            <img src={myProduct?.product?.coverImage} alt={myProduct?.product?.productName} />
            {myProduct?.product?.tags ? (
              <div className="card__tag">{myProduct?.product?.tags[0]?.tagName}</div>
            ) : null}
          </>
        )}
      </div>
      <div className="my-product__content d-flex flex-column ms-4 w-100">
        <h3 className="my-product__title">
          {"placeholder" in myProduct ? <Skeleton /> : myProduct?.product?.productName}
        </h3>
        {"placeholder" in myProduct ? (
          <Skeleton count={2.5} />
        ) : (
          <Fragment>
            <h4 className="my-product__date">
              Data {dayjs(myProduct?.product?.publishDate).format("DD/MM/YYYY")}
            </h4>
            <div className="my-product__teacher">
              {myProduct?.product?.productCategory === 41217 && <strong>Relatore: </strong>}
              {myProduct?.product?.productCategory === 41214 && <strong>Docente: </strong>}
              <span>
                {`${myProduct?.product?.author[0]?.authorName} ${myProduct?.product?.author[0]?.authorSurname}`}
              </span>
            </div>
            {myProduct.product?.link && (
              <div className="d-flex mt-auto justify-content-end">
                <a
                  href={myProduct.product.link}
                  className="my-product__button button button--secondary me-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Vai al corso
                </a>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default MyOthers;
