import Icon from "./icon";
import classNames from "classnames";

interface Props {
  className: string;
  title: string;
  items: footerItem[] | undefined;
  open?: boolean;
  handleClick?: (ev: React.MouseEvent) => void;
}

const FooterMenu: React.FC<Props> = ({ title, items, open, handleClick }) => {
  return (
    <div className={classNames("footer-menu", { open: open })}>
      <button className="footer-menu__title" onClick={handleClick}>
        <span>{title}</span>
        <Icon className="footer-menu__caret" name="caret" />
      </button>
      <div className="footer-menu__items">
        {items?.map((item: footerItem, i) => (
          <a
            key={i}
            className={"footer-menu__item"}
            href={item.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item.name}
          </a>
        ))}
      </div>
    </div>
  );
};

export default FooterMenu;
