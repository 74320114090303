import classNames from "classnames";
import { useForm } from "react-hook-form";
import Icon from "../components/icon";
import { useState } from "react";
import CustomModal from "../components/modal";
import { useProductActivation } from "../hooks/useQueryCustom";
import { toast } from "react-toastify";
import { useContext } from "react";
import UserContext from "../context/user";
import { ROLE_TEACHER } from "../utils/constants";
import { Link, Navigate } from "react-router-dom";
import Seo from "../components/seo";

const Activation = () => {
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const { user } = useContext(UserContext);
  const productActivation = useProductActivation();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm({ mode: "onChange" });

  const onSubmit = async (data: any) => {
    //console.log("form", data);
    const codeId = data.activationCode;
    await toast.promise(productActivation.mutateAsync({ codeId }), {
      pending: "Invio richiesta",
      success: {
        render({ data }) {
          return (
            <>
              Prodotto attivato con sucesso.
              <Link className="d-block pt-2" to="/my-catalogo">
                Vai ai tuoi prodotti
              </Link>
            </>
          );
        },
        autoClose: false,
      },
      error: {
        render() {
          return "Codice non valido";
        },
        autoClose: false,
      },
    });
    //console.log("result", result);
    /* const messageResult =
      result?.createPearsonActivationProductByCodeId?.messageResult || "Codice non valido"; */
  };

  if (user?.role === ROLE_TEACHER) return <Navigate to="/contatta-consulente" />;

  return (
    <div className="support">
      <Seo title="Attiva il prodotto" />
      <div className="page__header page__header--private">
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <div className="page__header-text d-flex flex-column justify-content-center">
                <h1 className="page__header-title">Attiva il prodotto</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="container-md">
          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
              <form
                className="support support--activation"
                name="support"
                onSubmit={handleSubmit(onSubmit)}
              >
                <h2>Inserisci il codice del libro</h2>
                <p className="my-4">
                  Per attivare i MyLab con i libri digitali dell'Università inserisci il codice di
                  attivazione studente che si trova sulla copertina del libro di testo.{" "}
                  <button className="fw-bold" type="button" onClick={() => setOpenInfo(true)}>
                    Dove lo trovo?
                  </button>
                </p>

                <div className="d-flex flex-column flex-md-row">
                  <button className="me-4" type="button" onClick={() => setOpenInfo(true)}>
                    <Icon
                      name="info-circle"
                      width="26px"
                      height="26px"
                      className="support__icon ms-2"
                    />
                  </button>
                  <input
                    className="form__input form__input--activation"
                    placeholder="es. RSLPEU-PRINK-HURRY-INLET-CABBY-FAKES"
                    {...register("activationCode", { required: true })}
                  />
                  <button
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    className={classNames(
                      "button button--tertiary button--w-xs-100",
                      { "button--tertiary": isValid },
                      { "button--disabled": !isValid || isSubmitting }
                    )}
                  >
                    <Icon name="check-circle" width="29" height="29" /> Attiva
                  </button>
                </div>
                <div className="my-3">
                  Il codice può essere utilizzato una sola volta e sarà associato all'account con
                  cui hai effettuato l'accesso.
                </div>

                <div className="mb-4">
                  {errors && errors["support-message"]?.type === "required" && (
                    <p className="form__error" role="alert">
                      Questo campo è richiesto
                    </p>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="page__footer page__footer--private">
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <h2 className="page__header-title mb-3">Hai bisogno di aiuto?</h2>
              <div className="row">
                <div className="col-md-4">
                  <div id="embedded_video" className="page__video mb-4">
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube-nocookie.com/embed/hSkv0bREGsU"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="content">
                    <h3 className="mb-4">
                      <strong>Come accedere alle risorse digitali per gli studenti</strong>
                    </h3>
                    <p>
                      Per maggiori informazioni su dove puoi trovare e come puoi utilizzare tutte le
                      risorse digitali per lo studente abbinate ai libri di testo guarda il
                      videotutorial.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <CustomModal
        headerText="Dove trovo il codice di attivazione?"
        type="alert"
        show={openInfo}
        onHide={() => setOpenInfo(false)}
      >
        <>
          <p>
            Per attivare i MyLab con i libri digitali dell'Università inserisci il codice di
            attivazione studente che si trova sulla copertina del libro di testo.
          </p>
          <img className="w-100" src="/attivazione-codice.jpg" alt="Attivazione codice" />
        </>
      </CustomModal>
    </div>
  );
};
export default Activation;
