import Icon from "./icon";
import { Link } from "react-router-dom";

type Props = {
  header: string;
  body: string;
  url: string;
};

const HelpCta = ({ header, body, url }: Props) => {
  return (
    <Link to="{url}" className="help-cta d-block">
      <div className="help-cta__header d-flex align-items-center justify-content-between p-3">
        <span>{header}</span>
        <Icon name="arrow-right" className="help-cta__icon" width="21px" height="17px" />
      </div>
      <div className="help-cta__body">{body}</div>
    </Link>
  );
};

export default HelpCta;
