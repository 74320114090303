import Bulletin from "../components/bulletin";
import LoadingError from "../components/loading-error";
import { useState, useContext } from "react";
import MyProductsWidget from "../components/my-products-widget";
import { Link } from "react-router-dom";
import MyOthers from "../components/my-others";
import placeholders from "../utils/placeholders";
import { useUserProduct } from "../hooks/useQueryCustom";
import UserContext from "../context/user";
import Icon from "../components/icon";
import Skeleton from "react-loading-skeleton";

const MyHome = () => {
  const [selectedProductId, setSelectedProductId] = useState<number | null>(null);
  const [createCourseId, setCreateCourseId] = useState<number | null>(null);
  const [enrollCourseId, setEnrollCourseId] = useState<number | null>(null);
  const { data, isError, error } = useUserProduct(4);
  const { isDocente } = useContext(UserContext);

  const selectProduct = (myProduct: MyProduct) => {
    setSelectedProductId(myProduct?.product?.productId);
    setCreateCourseId(myProduct?.product?.productId);
    setEnrollCourseId(myProduct?.product?.productId);
  };

  const handleCreateCourse = (myProduct: MyProduct) => {
    setSelectedProductId(myProduct?.product?.productId);
    setCreateCourseId(myProduct?.product?.productId);
  };

  const handleEnrollCourse = (myProduct: MyProduct) => {
    setSelectedProductId(myProduct?.product?.productId);
    setEnrollCourseId(myProduct?.product?.productId);
  };

  return (
    <div className="page mb-4">
      <div className="container-md">
        <div className="row">
          <div className="col-12">
            <div className="d-flex py-3 mt-5 info-block">
              <Icon name="welcome" height="60" width="80" />
              <div>
                <strong> Benvenuto nella tua area personale</strong>
                {isDocente() ? (
                  <p className="mt-2">
                    Da qui puoi accedere a tutti i tuoi prodotti digitali Pearson. <br />
                    Per richiedere la versione digitale del tuo testo{" "}
                    <Link to="/contatta-consulente">contatta il tuo consulente</Link>
                  </p>
                ) : (
                  <p className="mt-2">
                    Da qui puoi accedere a tutti i tuoi prodotti digitali Pearson. <br /> Per
                    accedere alla versione digitale del tuo testo,{" "}
                    <Link to="/attiva-prodotto">attiva il codice</Link> che trovi sulla copertina
                    del libro e segui le istruzioni.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Bulletin />
      {!data ? (
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <Skeleton width={"100%"} height="300px" baseColor={"#fff"} />
            </div>
          </div>
        </div>
      ) : (
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-start justify-content-between">
                <h2 className="page__section-title">Prodotti</h2>
                <Link to="/my-catalogo" className="button d-inline-flex d-md-none mb-2">
                  Visualizza altri
                </Link>
              </div>
            </div>
          </div>
          {isError ? (
            <LoadingError container={false} error={error} />
          ) : (
            <>
              <MyProductsWidget
                myProducts={data?.myProducts?.items || placeholders(2)}
                selectedProduct={data?.myProducts?.items?.find(
                  (i: MyProduct) => i?.product?.productId === selectedProductId
                )}
                handleBack={() => setSelectedProductId(null)}
                selectProduct={selectProduct}
                showCreateCourse={selectedProductId === createCourseId}
                showEnrollCourse={selectedProductId === enrollCourseId}
                handleEnrollCourse={handleEnrollCourse}
                handleCreateCourseCancel={() => setCreateCourseId(null)}
                handleEnrollCourseCancel={() => setSelectedProductId(null)}
                handleCreateCourse={handleCreateCourse}
              />
              {data?.myProducts.items.length === 0 && (
                <div className="col-12">
                  <h3 className="page__section--no-product">Nessun prodotto abilitato</h3>
                </div>
              )}
              {data?.myProducts?.lastPage > data?.myProducts?.page && (
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex justify-content-end">
                      <Link to="/my-catalogo" className="button d-none d-md-inline-flex my-2">
                        Visualizza altri
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {(data?.myWebinars.items.length > 0 || isError) && (
            <>
              <div className="row">
                <div className="col-12">
                  <div className="d-flex align-items-start justify-content-between mt-5">
                    <h2 className="page__section-title">Webinar</h2>
                    <Link to="/my-webinar" className="button d-inline-flex d-md-none mb-2">
                      Visualizza altri
                    </Link>
                  </div>
                </div>
              </div>
              {isError ? (
                <LoadingError container={false} error={error} />
              ) : (
                <>
                  <div className="row">
                    {(data?.myWebinars?.items || placeholders(2)).map(
                      (myWebinar: any, i: number) => (
                        <div key={i} className="col-12 col-md-6">
                          <MyOthers myProduct={myWebinar} />
                        </div>
                      )
                    )}
                  </div>

                  {data?.myWebinars?.lastPage > data?.myWebinars?.page && (
                    <div className="row">
                      <div className="col-12">
                        <div className="d-flex justify-content-end">
                          <Link to="/my-webinar" className="button d-none d-md-inline-flex my-2">
                            Visualizza altri
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}

          {(data?.myLearning.items.length > 0 || isError) && (
            <>
              <div className="row">
                <div className="col-12">
                  <div className="d-flex align-items-start justify-content-between mt-5">
                    <h2 className="page__section-title">Formazione</h2>
                    <Link to="/my-formazione" className="button d-inline-flex d-md-none mb-2">
                      Visualizza altri
                    </Link>
                  </div>
                </div>
              </div>
              {isError ? (
                <LoadingError container={false} error={error} />
              ) : (
                <>
                  <div className="row">
                    {(data?.myLearning.items || placeholders(2)).map(
                      (myLearning: any, i: number) => (
                        <div key={i} className="col-12 col-md-6">
                          <MyOthers myProduct={myLearning} />
                        </div>
                      )
                    )}
                    {data?.myLearning.items.length === 0 && (
                      <div className="col-12">
                        <h3 className="page__section--no-product">Nessun prodotto abilitato</h3>
                      </div>
                    )}
                  </div>

                  {data?.myLearning?.lastPage > data?.myLearning?.page && (
                    <div className="row">
                      <div className="col-12">
                        <div className="d-flex justify-content-end">
                          <Link to="/my-formazione" className="button d-none d-md-inline-flex my-2">
                            Visualizza altri
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}
      {/*  <div className="container-md">
        <MyHelp />
      </div> */}
    </div>
  );
};
export default MyHome;
