import { UseFormRegister } from "react-hook-form";

type Props = {
  address: Address;
  index: number;
  register: UseFormRegister<any>;
  editAddress: (address: Address) => void;
  checked: boolean;
  onClick: (id: number) => void;
};

const AddressItem = ({ address, index, register, editAddress, checked, onClick }: Props) => {
  return (
    <div className={`address-select__item${address.isFavourite ? "--preferred" : ""}`}>
      <div className="d-flex justify-content-between mb-2">
        <div className="d-flex align-items-center">
          <input
            id={`cb-${index}`}
            className="address-select__radio"
            type="radio"
            value={address.name}
            checked={checked}
            onClick={(e) => onClick(address?.addressId)}
            {...register("addressSelect")}
          />
          <label htmlFor={`cb-${index}`}>
            <strong>{address.name}</strong>
          </label>
        </div>
        <button type="button" className="address__link" onClick={() => editAddress(address)}>
          Modifica
        </button>
      </div>
      <div className="address-select__line">{address?.fullName}</div>
      {address?.near && <div className="address-select__line ">Presso: {address.near}</div>}
      <div className="address-select__line">{address.street}</div>
      <div className="address-select__line">
        {address.cap} {address.city}
      </div>
    </div>
  );
};

export default AddressItem;
