import CardContainer from "../components/card-container";
import Slider from "../components/slider";
import SearchWrapper from "../components/search-wrapper";
import { useNewProducts } from "../hooks/useQueryCustom";
import MenuPublic from "../components/menu-public";
import Seo from "../components/seo";

const Home = () => {
  const prodotti = useNewProducts("novita", "prodotto", 4);
  const formazione = useNewProducts("novita", "formazione", 4);
  const webinar = useNewProducts("novita", "webinar", 4);

  return (
    <>
      <Seo
        title="Pearson Place"
        sitename={false}
        description="Accedi ai miei prodotti per utilizzare o attivare i prodotti digitali associati ai tuoi libri di testo universitari"
      />
      <MenuPublic />
      <SearchWrapper>
        <Slider size={6} />
        {(prodotti.isLoading || prodotti?.data?.pages[0]?.pearsonProducts?.items.length > 0) && (
          <section className="section">
            <CardContainer
              result={prodotti}
              label="Novità"
              type="prodotto"
              path="/catalogo"
              size={4}
            />
          </section>
        )}

        {(webinar.isLoading || webinar?.data?.pages[0]?.pearsonProducts.length > 0) && (
          <section className="section">
            <CardContainer
              result={webinar}
              label="Webinar"
              type="webinar"
              path="/webinar"
              size={4}
            />
          </section>
        )}

        {(formazione.isLoading ||
          formazione?.data?.pages[0]?.pearsonProducts?.items.length > 0) && (
          <section className="section">
            <CardContainer
              result={formazione}
              label="Formazione"
              type="formazione"
              path="/formazione"
              size={4}
            />
          </section>
        )}
      </SearchWrapper>
    </>
  );
};

export default Home;
