import { Link } from "react-router-dom";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { ConditionalWrapper, stripHtml } from "../utils/utility";
import placeholders from "../utils/placeholders";
import Skeleton from "react-loading-skeleton";
import Icon from "../components/icon";
import { usePromo, useSlides } from "../hooks/useQueryCustom";
import LoadingError from "../components/loading-error";
import classNames from "classnames";
import { useMemo, useRef } from "react";

type Props = {
  type?: string;
  size?: number;
};

const Slider = ({ type = "", size = 3 }: Props) => {
  useSwiper();

  const { data, isError, isFetched } = useSlides("getSlides", type, size);
  const { data: dataPromo, isFetched: isFetchedPromo } = usePromo();
  const prevRef = useRef<HTMLButtonElement | null>(null);
  const nextRef = useRef<HTMLButtonElement | null>(null);
  const slides = useMemo(() => {
    if (isFetched && isFetchedPromo) {
      return dataPromo.length > 0 ? dataPromo : data;
    } else {
      return placeholders(1);
    }
  }, [data, dataPromo, isFetched, isFetchedPromo]);

  return isError ? (
    <LoadingError className="error-block--page" />
  ) : (
    <>
      <div className="slider">
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <div className="swiper-container position-relative w-100 px-3">
                <Swiper
                  autoHeight={false}
                  spaceBetween={110}
                  navigation={{
                    prevEl: prevRef.current,
                    nextEl: nextRef.current,
                  }}
                  onInit={(swiper) => {
                    if (swiper.params.navigation && prevRef.current && nextRef.current) {
                      swiper.params.navigation =
                        typeof swiper.params.navigation === "object"
                          ? {
                              ...swiper.params.navigation,
                              prevEl: prevRef.current,
                              nextEl: nextRef.current,
                            }
                          : swiper.params.navigation;
                    }
                    swiper.navigation.init();
                    swiper.navigation.update();
                  }}
                  pagination={{ clickable: true }}
                  modules={[Navigation, Pagination]}
                >
                  {slides.map((item: Slide, i: number) => (
                    <SwiperSlide key={i}>
                      <div className="d-md-flex">
                        <div className="slider__image">
                          {"placeholder" in item ? (
                            <Skeleton width={230} height={280} baseColor={"#fff"} />
                          ) : (
                            <ConditionalWrapper
                              condition={!!item.link}
                              wrapper={(children) => (
                                <Link to={item.link} title={item.title}>
                                  {children}
                                </Link>
                              )}
                            >
                              <img src={item.image} alt={item.title} />
                            </ConditionalWrapper>
                          )}
                        </div>
                        <div className="slider__content w-100">
                          <>
                            {!("placeholder" in item) ? (
                              <div
                                className={classNames("d-flex align-items-start", {
                                  "mb-3": item.productCategoryName !== "Prodotto" || item.new,
                                })}
                              >
                                {item.new && <div className="slider__tag me-3">Novità</div>}
                                {item.productCategoryName &&
                                  item.productCategoryName !== "Prodotto" && (
                                    <div className="slider__tag slider__tag--category">
                                      <Icon name={item.productCategoryName} />
                                      {item.productCategoryName}
                                    </div>
                                  )}
                              </div>
                            ) : (
                              <Skeleton width={100} height={20} className="mb-3" />
                            )}
                            <div className="slider__title">
                              {"placeholder" in item ? (
                                <Skeleton width={"50%"} />
                              ) : (
                                <ConditionalWrapper
                                  condition={!!item.link}
                                  wrapper={(children) => (
                                    <Link to={item.link} title={item.title}>
                                      {children}
                                    </Link>
                                  )}
                                >
                                  <>{item.title}</>
                                </ConditionalWrapper>
                              )}
                            </div>

                            <div className="slider__subtitle">
                              {"placeholder" in item ? <Skeleton width={"25%"} /> : item.subtitle}
                            </div>

                            {item.productCategoryName === "Prodotto" && item.edition ? (
                              <div className="slider__edition">
                                {"placeholder" in item ? <Skeleton width={"25%"} /> : item.edition}
                              </div>
                            ) : null}

                            <div className="slider__abstract">
                              {"placeholder" in item ? (
                                <Skeleton count={1.5} />
                              ) : (
                                stripHtml(item.description)
                              )}
                            </div>
                            {"placeholder" in item ? (
                              <Skeleton width={125} height={38} />
                            ) : (
                              <>
                                {item.link && (
                                  <Link
                                    to={item.link}
                                    className="button button--fw-normal button--fs-16"
                                  >
                                    Scopri di più
                                  </Link>
                                )}
                              </>
                            )}
                          </>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>

                <button
                  ref={nextRef}
                  aria-label="prodotto successivo"
                  className="swiper-button-next"
                ></button>
                <button
                  ref={prevRef}
                  aria-label="prodotto precedente"
                  className="swiper-button-prev"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Slider;
