import { useMemo } from "react";
import MyOthers from "../components/my-others";
import LoadingError from "../components/loading-error";
import placeholders from "../utils/placeholders";
import { useUserProductByCategory } from "../hooks/useQueryCustom";

const MyWebinars = () => {
  const {
    data: myWebinars,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isError,
    error,
  } = useUserProductByCategory("getProductsByUserId", "Webinar");

  const allItems = useMemo(() => {
    const all = myWebinars?.pages.reduce((prev, current) => {
      return prev.concat(current.pearsonProductsByUserId?.items);
    }, []);
    return all;
  }, [myWebinars]);

  return (
    <div className="section">
      <div className="container-md">
        <div className="row">
          <div className="col-12">
            <h2 className="page__section-title mb-4">Tutti i tuoi webinar</h2>
          </div>
          {isError ? (
            <LoadingError error={error} />
          ) : (
            <>
              {allItems?.length === 0 && (
                <div className="col-12">
                  <h3 className="page__section--no-product">Nessun prodotto abilitato</h3>
                </div>
              )}
              <div className="row">
                {(allItems || placeholders(4))?.map(
                  (myWebinar: MyProduct | Placeholder, i: number) => (
                    <div key={i} className="col-12 col-md-6">
                      <MyOthers myProduct={myWebinar} />
                    </div>
                  )
                )}
              </div>
            </>
          )}
          {hasNextPage && !isFetchingNextPage && (
            <div className="row">
              <div className="col-12 text-center mt-4 mb-5">
                <button onClick={() => fetchNextPage()} className="button button--secondary">
                  Carica di più
                </button>
              </div>
            </div>
          )}
          {/* <div className="related">
            <h2 className="related__title">Potrebbe interessarti</h2>
            <div className="related__buttons">
              <Link to="/catalogo" className="button button--secondary button--rounded">
                Prodotti
              </Link>
              <Link to="/formazione" className="button button--secondary button--rounded">
                Formazione
              </Link>
            </div>
          </div> */}
          {/*   <MyHelp /> */}
        </div>
      </div>
    </div>
  );
};

export default MyWebinars;
