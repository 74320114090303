import { useState } from "react";
import useClickOut from "../hooks/useClickOut";
import { Link } from "react-router-dom";

type Props = {
  user: User;
  logout: () => void;
};

const UserMenu: React.FC<Props> = ({ user, logout }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const menuRef = useClickOut(open, setOpen);
  return (
    <div ref={menuRef} className="usermenu">
      <button
        onClick={() => setOpen(!open)}
        className="usermenu__button d-flex align-items-center justify-content-center"
      >
        <span>
          {user.name.slice(0, 1)}
          {user.surname.slice(0, 1)}
        </span>
      </button>
      {open ? (
        <div className="usermenu__dropdown">
          <Link to="/user-info" className="usermenu__item">
            Modifica profilo
          </Link>
          <button className="usermenu__item" onClick={logout}>
            Esci
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default UserMenu;
