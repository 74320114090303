interface Props {
  description: string;
}

const Description = ({ description }: Props) => {
  return (
    <div className="mt-3">
      <div className="description" dangerouslySetInnerHTML={{ __html: description }}></div>
    </div>
  );
};
export default Description;
