import MyProductExpanded from "./my-product-expanded";
import MyProduct from "./my-product";
import { useEffect, useRef } from "react";

type Props = {
  selectedProduct: MyProduct | null;
  myProducts: MyProduct[] | Placeholder[];
  handleBack: () => void;
  showCreateCourse: boolean;
  showEnrollCourse: boolean;
  selectProduct: (myProduct: MyProduct) => void;
  handleCreateCourseCancel: () => void;
  handleEnrollCourseCancel: () => void;
  handleCreateCourse: (myProduct: MyProduct) => void;
  handleEnrollCourse: (myProduct: MyProduct) => void;
};

const MyProductsWidget = ({
  myProducts,
  selectedProduct,
  handleBack,
  showCreateCourse,
  showEnrollCourse,
  selectProduct,
  handleCreateCourse,
  handleEnrollCourse,
  handleCreateCourseCancel,
  handleEnrollCourseCancel,
}: Props) => {
  const myRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    myRef?.current?.scrollIntoView();
  }, [selectedProduct]);

  return (
    <>
      {selectedProduct ? (
        <div className="row" ref={myRef}>
          <div className="col-12">
            <MyProductExpanded
              myProduct={selectedProduct}
              handleBack={handleBack}
              showCreateCourse={showCreateCourse}
              showEnrollCourse={showEnrollCourse}
              onCreateCourseCancel={handleCreateCourseCancel}
              onEnrollCourseCancel={handleEnrollCourseCancel}
              onCreateCourse={handleCreateCourse}
            />
          </div>
        </div>
      ) : (
        <>
          <div className="row">
            {myProducts?.map((item: any, i: number) => (
              <div key={i} className="col-12 col-md-6">
                <MyProduct
                  myProduct={item}
                  selectProduct={selectProduct}
                  handleCreateCourse={handleCreateCourse}
                  handleEnrollCourse={handleEnrollCourse}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default MyProductsWidget;
