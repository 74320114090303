import CardContainer from "../components/card-container";
import MenuPublic from "../components/menu-public";
import SearchWrapper from "../components/search-wrapper";
import Seo from "../components/seo";
import { useProducts } from "../hooks/useQueryCustom";

const Learning = () => {
  const formazione = useProducts("getLearning", "formazione", 6);

  return (
    <>
      <Seo title="Formazione" />
      <MenuPublic />
      <SearchWrapper type="formazione">
        <section className="section">
          {formazione && (
            <CardContainer
              result={formazione}
              type="formazione"
              label={"Formazione"}
              more={true}
              size={6}
            />
          )}
        </section>
      </SearchWrapper>
    </>
  );
};

export default Learning;
