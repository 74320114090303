import { useMemo } from "react";
import LoadingError from "../components/loading-error";
import MyOthers from "../components/my-others";
import placeholders from "../utils/placeholders";
import { useUserProductByCategory } from "../hooks/useQueryCustom";

const MyLearning = () => {
  const {
    data: myLearnings,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isError,
    error,
  } = useUserProductByCategory("getProductsByUserId", "Formazione");

  const allItems = useMemo(() => {
    const all = myLearnings?.pages.reduce((prev, current) => {
      return prev.concat(current.pearsonProductsByUserId?.items);
    }, []);
    return all;
  }, [myLearnings]);

  return (
    <div className="section">
      <div className="container-md">
        <div className="row">
          <div className="col-12">
            <h2 className="page__section-title mb-4">Tutta la tua formazione</h2>
          </div>
          {isError ? (
            <LoadingError error={error} />
          ) : (
            <>
              {allItems?.length === 0 && (
                <div className="col-12">
                  <h3 className="page__section--no-product">Nessun prodotto abilitato</h3>
                </div>
              )}
              <div className="row">
                {(allItems || placeholders(2)).map(
                  (myLearning: MyProduct | Placeholder, i: number) => (
                    <div key={i} className="col-12 col-md-6">
                      <MyOthers myProduct={myLearning} />
                    </div>
                  )
                )}
              </div>
            </>
          )}
          {hasNextPage && !isFetchingNextPage && (
            <div className="row">
              <div className="col-12 text-center mt-4 mb-5">
                <button onClick={() => fetchNextPage()} className="button button--secondary">
                  Carica di più
                </button>
              </div>
            </div>
          )}
          {/*  <div className="related">
            <h2 className="related__title">Potrebbe interessarti</h2>
            <div className="related__buttons">
              <Link to="/catalogo" className="button button--secondary button--rounded">
                Prodotti
              </Link>
              <Link to="/webinar" className="button button--secondary button--rounded">
                Webinar
              </Link>
            </div>
          </div> */}
          {/*   <MyHelp /> */}
        </div>
      </div>
    </div>
  );
};

export default MyLearning;
