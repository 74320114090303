import { createContext, useCallback, useEffect, useMemo, useState, useRef } from "react";
import { changePearsonUser, getIESUser, getPearsonUser, updatePearsonUser } from "../utils/user";
import Cookies from "js-cookie";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import FullPageOverlay from "../components/full-page-overlay";
import { toast } from "react-toastify";
import { enrollCourse } from "../utils/products";
import { ROLE_ADMIN, ROLE_STUDENT, ROLE_TEACHER } from "../utils/constants";
const client_id = import.meta.env.VITE_IES_CLIENTID;

const UserContext = createContext<UserContextType>({
  user: undefined,
  userImp: undefined,
  isDocente: () => false,
  isStudente: () => false,
  getUserDataForQuery: () => null,
  login: (returnUrl?: string) => undefined,
  logout: () => undefined,
  updateUser: () => Promise.resolve(),
  stopImpersonification: () => undefined,
  checkIESSession: () => false,
  sessionExpired: () => undefined,
  changeDuplicateUser: () => Promise.resolve(),
});

type UserContextType = {
  user?: User | null;
  userImp?: User | null;
  isDocente: () => boolean;
  isStudente: () => boolean;
  getUserDataForQuery: () => UserDataForQuery | null;
  login: (returnUrl?: string) => void;
  logout: () => void;
  updateUser: (variables: any) => Promise<void>;
  stopImpersonification: () => void;
  checkIESSession: () => boolean;
  sessionExpired: () => void;
  changeDuplicateUser: (ticket: string) => Promise<void>;
};

type Props = {
  children: React.ReactNode;
};

export const UserProvider: React.FC<Props> = ({ children }) => {
  const loadedContent = useRef(false);
  const loadedLocalUser = useRef(false);
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const [loginOverlay, setLoginOverlay] = useState(false);

  const [user, setUser] = useState<User | undefined | null>(undefined);
  const [userImp, setUserImp] = useState<User | undefined | null>(undefined);
  const imp = searchParams.get("userid") || sessionStorage.getItem("userIdImp");
  const code = searchParams.get("code");
  const productId = searchParams.get("productId");

  const getUserDataForQuery = useCallback(() => {
    return {
      userId: user?.userId,
      userIdImp: userImp?.userId,
      userRole: userImp?.role || user?.role,
      userEmail: user?.email,
    };
  }, [user, userImp]);

  const updateUser = useCallback(async (data: any) => {
    const userData = await updatePearsonUser(data);
    setUser(userData);
  }, []);

  const changeDuplicateUser = useCallback(
    async (ticket: string) => {
      const userData = getUserDataForQuery();
      const data = await changePearsonUser(ticket, userData);
      setUser(data);
    },
    [user, getUserDataForQuery]
  );

  const login = useCallback((returnUrl: string = window.location.href) => {
    if (window.piSession) {
      window.piSession.login(returnUrl, 4000);
    } else {
      alert("servizio non disponbile");
      console.warn("PiSession in not defined");
    }
  }, []);

  const logout = useCallback(
    (redirect = true) => {
      removeCookie(true);

      if (redirect) navigate("/", { replace: true });
    },
    [navigate]
  );

  const loadUserImp = useCallback(
    async (imp: string) => {
      //console.log("loadUserImp", imp, userImp);
      if (
        user &&
        imp &&
        user.role === ROLE_ADMIN &&
        user.userId !== imp &&
        (!userImp || userImp.userId !== imp)
      ) {
        const data = await getPearsonUser({ userId: user.userId, userIdImp: imp });
        if (data?.userId !== user.userId) {
          sessionStorage.setItem("userIdImp", imp);
          setUserImp(data);
        }
      } else if (!user) {
        setUserImp(null);
      }
    },
    [user, userImp]
  );

  useEffect(() => {
    if (imp) loadUserImp(imp);
  }, [imp, loadUserImp]);

  const activateCourse = useCallback(async () => {
    const ud = { userId: user?.userId, userIdImp: undefined, userRole: ROLE_STUDENT };
    await toast.promise(enrollCourse(ud as UserDataForQuery, Number(productId), Number(code)), {
      pending: "Richiesta di attivazione corso",
      success: {
        render({ data }) {
          return (
            <>
              Operazione completata con successo
              <Link className="d-block pt-2" to={`/my-catalogo/${productId}`}>
                Vai al prodotto
              </Link>
            </>
          );
        },
        autoClose: false,
      },
      error: {
        render({ data }: any) {
          if (data?.message === "no-product")
            return (
              <>
                Prodotto non attivo.
                <Link className="d-block pt-2" to="/attiva-prodotto">
                  Attiva il prodotto
                </Link>
              </>
            );
          else if (data?.message === "course-does-not-exist") {
            return "Il codice corso inserito non esiste: conttatta il tuo docente";
          } else return "Si è verificato un errore";
        },
        autoClose: false,
      },
    });
  }, [code, productId, user]);

  useEffect(() => {
    if (code && productId) {
      if (user !== undefined && user === null) {
        login();
      } else if (user) {
        activateCourse();
      }
    }
  }, [code, productId, user, activateCourse, login]);

  const stopImpersonification = useCallback(() => {
    sessionStorage.removeItem("userIdImp");
    if (user) {
      setUserImp(null);
      navigate("/", { replace: true });
    }
  }, [user, navigate]);

  const sessionExpired = useCallback(() => {
    logout();
    toast.error("Sessione scaduta");
  }, [logout]);

  const afterIESLogin = useCallback(
    async (identityId: string, token: string) => {
      setLoginOverlay(true);
      try {
        const data = await getIESUser(identityId, token);
        const { givenName, familyName, emails } = data.user.data;
        const email = emails.find((item: any) => item.isPrimary)?.emailAddress;
        const variables = {
          userId: identityId,
          email,
          name: givenName,
          surname: familyName,
          universityId: 0,
        };
        await updateUser(variables);
      } catch (error) {
        console.log("error afterIESLogin", error);
        setLoginOverlay(false);
        logout();
        throw error;
      }
      setLoginOverlay(false);
    },
    [updateUser, logout]
  );

  const checkIESSession = useCallback(
    (time = 60) => {
      if (window.piSession.hasValidSession(time) !== "success") {
        sessionExpired();
        return false;
      }
      return true;
    },
    [sessionExpired]
  );

  // const checkSession = useCallback(() => {
  //   if (Cookies.get("X-Pearson-userID")) {
  //     return true;
  //   } else if (window.piSession.hasValidSession(60) === "success") {
  //     window.piSession.getToken((status: string, token: string) => {
  //       //console.log("token 2", token);
  //       const identityId = window.piSession.userId();
  //       toast.promise(afterIESLogin(identityId, token), {
  //         pending: "Recupero profilo",
  //         success: "Operazione completata",
  //         error: "Servizio non disponibile",
  //       });
  //     });
  //     return false;
  //   } else {
  //     sessionExpired();
  //     return false;
  //   }
  // }, [sessionExpired, afterIESLogin]);

  const localLogin = useCallback(async (variables: any) => {
    setLoginOverlay(true);
    try {
      const data = await toast.promise(getPearsonUser(variables), {
        pending: "Recupero profilo",
        success: "Operazione completata",
        error: "Si è verificato un errore",
      });
      //const data = await getPearsonUser(variables);
      if (data.userId) {
        setUser(data);
      } else {
        logout(false);
      }
    } catch (error) {
      removeCookie();
      console.log("error localLogin");
    }
    setLoginOverlay(false);
  }, []);

  useEffect(() => {
    const pathname = window.location.pathname;

    if (user && user?.duplicate && pathname !== "/cambia-utente") {
      navigate("/cambia-utente", { replace: true, state: { from: window.location.pathname } });
    } else if (
      user &&
      (!user.role || (!user.university?.universityId && user.role !== ROLE_ADMIN)) &&
      pathname !== "/user-info" &&
      pathname !== "/cambia-utente"
    ) {
      navigate("/user-info", { replace: true, state: { from: window.location.pathname } });
    } else if (user && !user?.duplicate && pathname === "/cambia-utente") {
      navigate("/", { replace: true });
    }
  }, [user, navigate]);

  useEffect(() => {
    const cid = Cookies.get("X-Pearson-userID");
    if (!loadedLocalUser.current) {
      loadedLocalUser.current = true;
      if (cid) {
        localLogin({ userId: cid });
      }
    }
  }, [localLogin]);

  useEffect(() => {
    const cid = Cookies.get("X-Pearson-userID");
    if (!loadedContent.current) {
      loadedContent.current = true;
      if (window.piSession) {
        window.piSession.initialize(client_id, {
          sessionIdleTimeoutSeconds: 7200,
          avoidReInit: true,
        });
        const session = window.piSession.hasValidSession(10);

        const initUser = (e: boolean) => {
          if (e) {
            window.piSession.getToken((status: string, token: string) => {
              //console.log("token 2", token);
              const identityId = window.piSession.userId();
              toast.promise(afterIESLogin(identityId, token), {
                pending: "Recupero profilo",
                success: "Operazione completata",
                error: {
                  render({ data }: any) {
                    return "Si è verificato un errore";
                  },
                  autoClose: false,
                },
              });
            });
          } else {
            console.log("non loggato");
            setUser(null);
          }
        };

        window.piSession.on("logout", (e: any) => {
          removeCookie();
          console.log("logout", e);
        });

        if (!cid) {
          if (session && session !== "unknown") {
            initUser(true);
          } else {
            window.piSession.on("sessiontimingout", (e: any) => {
              console.log("sessiontimingout", e);
              window.piSession.extendUserSession();
            });
            window.piSession.on("sessionstateknown", (e: any) => {
              console.log("sessionstateknown", e);
              initUser(!!e.data);
            });
          }
        } else {
          const IESUserId = window.piSession.userId();
          if (IESUserId && cid !== IESUserId) {
            console.log("cambio utenza");
            removeCookie();
            initUser(true);
          } else if (IESUserId) {
            console.log("session is valid");
            window.piSession.on("sessiontimingout", (e: any) => {
              console.log("sessiontimingout", e);
              window.piSession.extendUserSession();
            });
          }
        }
      } else {
        console.warn("PiSession in not defined");
      }
    }
  }, [afterIESLogin, logout]);

  const isDocente = useCallback(() => {
    return userImp?.role === ROLE_TEACHER || user?.role === ROLE_TEACHER;
  }, [user, userImp]);

  const isStudente = useCallback(() => {
    return userImp?.role === ROLE_STUDENT || user?.role === ROLE_STUDENT;
  }, [user, userImp]);

  const removeCookie = async (logoutIES: boolean = false) => {
    setUser(null);
    const cid = Cookies.get("X-Pearson-userID");
    if (cid) {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Pearson-userID": cid,
        },
      };
      const url = `/api/user-logout`;
      await fetch(url, options);
      if (logoutIES) {
        if (window?.piSession) {
          window.piSession.logout();
        }
      }
    }
  };

  const contextValue = useMemo(
    () => ({
      user,
      userImp,
      isDocente,
      isStudente,
      getUserDataForQuery,
      login,
      logout,
      updateUser,
      stopImpersonification,
      checkIESSession,
      sessionExpired,
      changeDuplicateUser,
    }),
    [
      user,
      userImp,
      isDocente,
      isStudente,
      getUserDataForQuery,
      login,
      logout,
      updateUser,
      stopImpersonification,
      checkIESSession,
      sessionExpired,
      changeDuplicateUser,
    ]
  );

  return (
    <UserContext.Provider value={contextValue}>
      <>
        {loginOverlay && <FullPageOverlay />}
        {children}
      </>
    </UserContext.Provider>
  );
};

export default UserContext;
