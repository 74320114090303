import classNames from "classnames";
import { useForm } from "react-hook-form";
import Icon from "../components/icon";
import { toast } from "react-toastify";
import { useContext } from "react";
import UserContext from "../context/user";
import Seo from "../components/seo";
import { getNewToken } from "../utils/user";

const ChangeUser = () => {
  const { user, changeDuplicateUser, getUserDataForQuery, logout } = useContext(UserContext);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });

  const onSubmit = async (data: any) => {
    //console.log("form", data);
    const ticket = data.ticketId;
    toast.promise(changeDuplicateUser(ticket), {
      pending: "Invio richiesta",
      success: {
        render() {
          return <>Operazione completata con successo</>;
        },
        autoClose: false,
      },
      error: {
        render({ data }: any) {
          if (data?.message === "token-expired") {
            return "Il codice inserito è scaduto, richiedi un nuovo codice";
          } else return "il codice inserito non è valido";
        },
        autoClose: false,
      },
    });
    //console.log("result", result);
    /* const messageResult =
      result?.createPearsonActivationProductByCodeId?.messageResult || "Codice non valido"; */
  };

  const getNewCode = async () => {
    const userData = getUserDataForQuery();
    userData &&
      toast.promise(getNewToken(userData), {
        pending: "Invio richiesta",
        success: {
          render() {
            return <>Codice inviato</>;
          },
        },
        error: {
          render() {
            return <>Si è verificato un errore, contatta l'assistenza</>;
          },
          autoClose: false,
        },
      });
  };

  return (
    <div className="support">
      <Seo title="Attiva il prodotto" />
      <div className="page__header page__header--private">
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <div className="page__header-text d-flex flex-column justify-content-center">
                <h1 className="page__header-title">Conferma nuova utenza</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="container-md">
          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
              <form
                className="support support--activation"
                name="support"
                onSubmit={handleSubmit(onSubmit)}
              >
                <h2>Inserisci il codice ricevuto via email</h2>
                <p className="my-4">
                  Nei nostri sistemi esiste già un account con l’indirizzo email{" "}
                  <strong>{user?.email}</strong>. <br />
                  Per procedere con la creazione del nuovo account inserisci qui sotto il codice di
                  verifica che ti abbiamo inviato via email.
                </p>

                <div className="d-flex flex-column flex-md-row">
                  <input
                    maxLength={36}
                    minLength={36}
                    className="form__input form__input--activation"
                    placeholder="es. 69ac71cf-c836-3bdd-c23e-fb31a54c51b6"
                    {...register("ticketId", { required: true })}
                  />
                  <button
                    type="submit"
                    className={classNames(
                      "button button--tertiary button--w-xs-100",
                      { "button--tertiary": isValid },
                      { "button--disabled": !isValid }
                    )}
                  >
                    <Icon name="check-circle" width="29" height="29" /> Valida
                  </button>
                  <button
                    type="button"
                    onClick={() => logout()}
                    className={classNames("button button--secondary button--w-xs-100 ms-3")}
                  >
                    <Icon name="xmark-circle" width="29" height="29" /> Annulla
                  </button>
                </div>
                <div className="my-4">
                  Il codice può essere utilizzato una sola volta e ha una validità di cinque ore.
                  <div className="mt-3">
                    <button
                      type="button"
                      className="button button--secondary"
                      onClick={() => getNewCode()}
                    >
                      Richiedi un altro codice
                    </button>
                  </div>
                </div>

                <div className="mb-4">
                  {errors && errors["support-message"]?.type === "required" && (
                    <p className="form__error" role="alert">
                      Questo campo è richiesto
                    </p>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChangeUser;
