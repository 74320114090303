import Icon from "./icon";
import classNames from "classnames";
import Modal from "react-overlays/Modal";

type Props = {
  headerText: string;
  confirmButtonText?: string;
  type: "alert" | "confirm";
  scrollable?: boolean;
  show: boolean;
  onHide?: () => void;
  onConfirm?: () => void;
  children: JSX.Element;
};
const renderBackdrop = () => <div className="modal__external"></div>;

const CustomModal = ({
  headerText,
  confirmButtonText,
  type,
  scrollable,
  show,
  children,
  onHide,
  onConfirm,
}: Props) => {
  return (
    <Modal
      className="modal__internal d-flex flex-column"
      show={show}
      renderBackdrop={renderBackdrop}
    >
      <>
        <div className="modal__header">
          <span>{headerText}</span>
          {!!onHide && (
            <button className="modal__close button" onClick={onHide}>
              <Icon name="close" width="18px" height="18px" />
            </button>
          )}
        </div>
        <div
          className={classNames(
            "flex-fill",
            { modal__body: !scrollable },
            { "modal__body--scrollable": scrollable }
          )}
        >
          {children}
        </div>
        {type === "confirm" ? (
          <div className="modal__actions">
            <div className="d-flex">
              <div className="flex-grow-1 pe-2">
                <button className="button button--secondary w-100" onClick={onHide}>
                  Annulla
                </button>
              </div>
              <div className="flex-grow-1 ps-2">
                <button className="button button--tertiary w-100" onClick={onConfirm}>
                  {confirmButtonText ? confirmButtonText : "Conferma"}
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </>
    </Modal>
  );
};
export default CustomModal;
