import { callApiQl } from "../utils/call-api";
import { gql } from "graphql-request";
import { GROUP_ID } from "./search";

export const getListAddressByUserId = async (operationName: string, userId: string) => {
  //console.log("getListAddressByUserId query");

  const variables = { userId };
  const data = await callApiQl(
    gql`
        query ${operationName}($userId: String!) {
          pearsonUserAddressByUserId(userId: $userId, groupId: ${GROUP_ID}) {
            items {
              addressId
              cap
              city
              country
              province
              phoneNumber
              near
              groupId
              isFavourite
              isRegistrationPhase
              name
              street
              fullName
              userId
            }
          }
        }
      `,
    variables,
    false
  );
  return data;
};

export const addAdrress = async (userId: string, address: Address) => {
  const variables = { userId, ...address };
  const data = await callApiQl(
    gql`
        mutation pearsonUserAddressByUserId(
          $userId: String!
          $cap: String!
          $city: String!
          $province: String!
          $phoneNumber: String!
          $isFavourite: Boolean!
          $name: String!
          $fullName: String!
          $street: String!
          $near: String
        ) {
          createPearsonUserAddressByAddressId(
            userId: $userId
            pearsonAddress: {
              addressId: 0
              cap: $cap
              city: $city
              country: "Italia"
              province: $province
              phoneNumber: $phoneNumber
              groupId: ${GROUP_ID}
              isFavourite: $isFavourite
              name: $name
              fullName: $fullName
              street: $street
              userId: $userId
              near: $near
            }
          ) {
            addressId
            cap
            city
            country
            province
            phoneNumber
            near
            groupId
            isFavourite
            name
            street
            fullName
            userId
          }
        }
      `,
    variables,
    false
  );
  return data;
};

export const updateAdrress = async (userId: string, address: Address) => {
  const variables = { userId, ...address };
  const data = await callApiQl(
    gql`
        mutation pearsonUserAddressByUserId(
          $userId: String!
          $addressId: Long!
          $cap: String!
          $city: String!
          $province: String!
          $phoneNumber: String!
          $isFavourite: Boolean!
          $name: String!
          $fullName: String!
          $street: String!
          $near: String
        ) {
          updatePearsonUserAddressByAddressId(
            userId: $userId
            addressId: $addressId
            pearsonAddress: {
              addressId: $addressId
              cap: $cap
              city: $city
              country: "Italia"
              province: $province
              phoneNumber: $phoneNumber
              groupId: ${GROUP_ID}
              isFavourite: $isFavourite
              name: $name
              fullName: $fullName
              street: $street
              userId: $userId
              near: $near
            }
          ) {
            addressId
            cap
            city
            country
            province
            phoneNumber
            near
            groupId
            isFavourite
            name
            street
            fullName
            userId
          }
        }
      `,
    variables,
    false
  );
  return data;
};

export const deleteAdrress = async (userId: string, address: Address) => {
  const variables = { userId, addressId: address.addressId };
  const data = await callApiQl(
    gql`
      mutation pearsonUserAddressByUserId($userId: String!, $addressId: Long!) {
        deletePearsonUserAddressByAddressId(userId: $userId, addressId: $addressId) {
          addressId
          cap
          city
          country
          province
          phoneNumber
          near
          groupId
          isFavourite
          name
          street
          fullName
          userId
        }
      }
    `,
    variables,
    false
  );
  return data;
};

export const addCopyRequest = async (userId: string, formdata: any) => {
  const {
    address,
    assessment,
    corsoDiLaurea,
    degreeCourse,
    productId,
    semester,
    studentNumber,
    subject,
  } = formdata;
  const variables = {
    userId,
    addressId: address,
    assessment: assessment.value,
    corsoDiLaurea,
    degreeCourse,
    productId,
    semester,
    studentNumber,
    subject,
  };
  const data = await callApiQl(
    gql`
      mutation createPearsonCopyRequest(
        $userId: String!
        $productId: Long!
        $addressId: Long!
        $assessment: String!
        $degreeCourse: String!
        $semester: String!
        $studentNumber: Int!
        $subject: String
      ){
        createPearsonCopyRequest(
          userId: $userId, 
          pearsonCopyRequest:{
            userId: $userId, 
            productId: $productId, 
            addressId: $addressId, 
            assessment: $assessment, 
            degreeCourse: $degreeCourse, 
            groupId: ${GROUP_ID}, 
            requestId: 0, 
            semester: $semester, 
            studentNumber: $studentNumber, 
            subject: $subject 
          })
        {
          userId
          addressId,
          productId,
          requestId,
          assessment,
          degreeCourse,
          groupId,
          semester,
          studentNumber,
          subject
        }
      }
      `,
    variables,
    false
  );
  return data;
};
