import Icon from "./icon";

const Social = () => {
  return (
    <div className="social">
      <div className="d-flex align-items-md-center">
        <div className="social__label me-1">Seguici su</div>
        <div className="d-block d-lg-flex align-items-md-center">
          <div className="social__line">
            <a
              className="social__link"
              href="https://www.linkedin.com/showcase/pearson-higher-education/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn"
              title="Linkedin"
            >
              <Icon className="social__icon" name="linkedin" />
            </a>
            <a
              className="social__link"
              href="https://youtube.com/@pearsonheitalia2495"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Youtube Pearson"
              title="Youtube Pearson"
            >
              <Icon className="social__icon" name="youtube" />
            </a>
          </div>
          {/* <div className="social__line">
          <a
              className="social__link"
              href="/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Whatsapp"
            >
              <Icon className="social__icon" name="whatsapp" />
            </a>
            <a
              className="social__link"
              href="/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Skype"
            >
              <Icon className="social__icon" name="skype" />
            </a>
            <a
              className="social__link"
              href="/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Pinterest"
            >
              <Icon className="social__icon" name="pinterest" />
            </a>
            <a
              className="social__link"
              href="/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Vimeo"
            >
              <Icon className="social__icon" name="vimeo" />
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Social;
