import { useState } from "react";
import { useForm } from "react-hook-form";
import Icon from "./icon";
import Skeleton from "react-loading-skeleton";
import CustomModal from "./modal";
import placeholders from "../utils/placeholders";
import dayjs from "dayjs";
import "dayjs/locale/it";
import { useAddCourse, useRenameCourse, useDeleteCourse } from "../hooks/useQueryCustom";
import { toast } from "react-toastify";

type Props = {
  myProduct: MyProduct;
  showCreateCourse: boolean;
  onCreateCourseCancel: () => void;
};

const MyCourses = ({ myProduct, showCreateCourse, onCreateCourseCancel }: Props) => {
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [showCopiedCode, setShowCopiedCode] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [linkToShare, setLinkToShare] = useState<string | null>(null);
  const [newCourseName, setNewCourseName] = useState<string>("");
  const [courseToDelete, setCourseToDelete] = useState<Class | null>(null);
  const [courseToRename, setCourseToRename] = useState<Class | null>(null);

  const { register, handleSubmit, reset } = useForm();
  const addCourse = useAddCourse();
  const renameCourse = useRenameCourse();
  const deleteCourse = useDeleteCourse();
  //console.log("myProduct", myProduct);
  const onCreateFormSubmit = async (data: any) => {
    const payload = {
      productId: myProduct.product.productId,
      moodleId: myProduct.product.moodleId,
      courseName: data.courseName,
    };
    await toast.promise(addCourse.mutateAsync(payload), {
      pending: "Creazione corso",
      success: "Operazione completata",
      error: "Si è verificato un errore",
    });
  };

  const onRenameSubmit = async (courseName: string) => {
    const courseId = courseToRename?.courseId as number;
    //console.log("courseId", courseId, "courseName", newCourseName);
    await toast.promise(renameCourse.mutateAsync({ courseId, courseName }), {
      pending: "Aggiornamento in corso",
      success: "Operazione completata",
      error: "Si è verificato un errore",
    });
  };

  const onDeleteSubmit = async () => {
    const courseId = courseToDelete?.courseId as number;
    //console.log("courseId", courseId, "courseName", newCourseName);
    await toast.promise(deleteCourse.mutateAsync({ courseId }), {
      pending: "Aggiornamento in corso",
      success: "Operazione completata",
      error: "Si è verificato un errore",
    });
    setCourseToDelete(null);
  };

  const resetCreateForm = () => {
    reset();
    onCreateCourseCancel();
  };

  return (
    <div className="my-courses">
      {!myProduct.product.defaultProduct && (
        <div>
          {showCreateCourse ? (
            <div className="row">
              <div className="col-12">
                <h2 className="my-courses__title mb-3">I miei corsi</h2>
              </div>
              <div className="col-12">
                <form className="my-courses-form mb-4" onSubmit={handleSubmit(onCreateFormSubmit)}>
                  <p>
                    Una volta creato un corso, potrai assegnare materiali di studio ed esercitazioni
                    e monitorare l’andamento dei tuoi studenti comunicando loro il codice con cui
                    iscriversi al corso.
                  </p>
                  <strong className="d-inline-block mb-1">Inserisci il nome del tuo corso</strong>
                  <div className="d-flex flex-column flex-md-row mt-1">
                    <input
                      type="text"
                      aria-label="nome del corso"
                      className="my-courses-form__input"
                      placeholder="es. insegnamento+anno accademico"
                      {...register("courseName")}
                    />
                    <div className="my-courses-form__actions">
                      <button type="submit" className="button button--tertiary">
                        Crea il corso
                      </button>
                      <button
                        type="button"
                        aria-label="annulla"
                        onClick={resetCreateForm}
                        className="button button--secondary"
                      >
                        Annulla
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h2 className="my-courses__title">I miei corsi</h2>
              <button type="submit" className="button button--tertiary">
                Crea nuovo corso
              </button>
            </div>
          )}
        </div>
      )}
      <div className="my-courses__list">
        {(myProduct.courses || placeholders(2)).map((item: Course | Placeholder, i: number) =>
          "placeholder" in item ? (
            <div className="my-3" key={i}>
              <Skeleton count={1.5} />
            </div>
          ) : (
            <div className="row" key={i}>
              <div className="col-12 col-lg">
                <div className="my-courses__col">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="my-courses__label my-courses__label--link"
                    href={item.url}
                  >
                    {item.name}
                  </a>
                  <div className="my-courses__date">
                    {dayjs(item.createDate).locale("it").format("MMMM YYYY")}
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg">
                <div className="my-courses__col">
                  <div className="my-courses__label">Materia</div>
                  <div>{myProduct?.product?.subjectCategoryName}</div>
                </div>
              </div>
              <div className="col-12 col-lg">
                <div className="my-courses__col">
                  <div className="my-courses__label">Codice corso</div>
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(item?.code.toString());
                      setShowCopiedCode(true);
                    }}
                    className="my-courses__label--link"
                  >
                    <Icon className="me-2" name="copy" width="20px" height="20px" />
                    <span>{item?.code}</span>
                  </button>
                </div>
              </div>
              <div className="col-12 col-lg-4 my-courses__col--actions d-flex align-items-center justify-content-between">
                <div className="d-none d-md-flex align-items-center">
                  <button
                    aria-label="condividi"
                    onClick={() => {
                      setLinkToShare(
                        `${window.location.origin}/?productId=${item.productId}&code=${item.code}`
                      );
                      setShowShareModal(true);
                    }}
                    className="my-courses__icon share"
                  >
                    <Icon name="share" width="40px" height="30px" />
                  </button>
                  {!item.isIndipendent && !myProduct.product.defaultProduct && (
                    <button
                      aria-label="modifica nome"
                      onClick={() => {
                        setCourseToRename(item);
                        setShowEditModal(true);
                      }}
                      className="my-courses__icon edit"
                    >
                      <Icon name="edit" width="20px" height="20px" />
                    </button>
                  )}
                  {!item.isIndipendent && !myProduct.product.defaultProduct && (
                    <button
                      aria-label="cancella corso"
                      onClick={() => {
                        setCourseToDelete(item);
                        setShowDeleteModal(true);
                      }}
                      className="my-courses__icon trash"
                    >
                      <Icon name="trash" width="20px" height="20px" />
                    </button>
                  )}
                </div>
                <div>
                  <a
                    href={item?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-none d-md-flex button button--secondary"
                  >
                    Vai al corso
                  </a>
                </div>
                <div className="d-flex d-md-none w-100">
                  <button
                    onClick={() => {
                      setLinkToShare(item?.url);
                      setShowShareModal(true);
                    }}
                    className="button button--secondary flex-fill"
                  >
                    Condividi
                  </button>
                  <button
                    onClick={() => {
                      setCourseToRename(item);
                      setShowEditModal(true);
                    }}
                    className="button button--secondary flex-fill ms-2 me-2"
                  >
                    Modifica
                  </button>
                  <button
                    onClick={() => {
                      setCourseToDelete(item);
                      setShowDeleteModal(true);
                    }}
                    className="button button--secondary flex-fill"
                  >
                    Cancella
                  </button>
                </div>
              </div>
            </div>
          )
        )}
      </div>
      <CustomModal
        headerText="Condividi il corso"
        confirmButtonText="Copia"
        type="confirm"
        show={showShareModal}
        onHide={() => setShowShareModal(false)}
        onConfirm={() => linkToShare && navigator.clipboard.writeText(linkToShare)}
      >
        <>
          <p>Copia il link per invitare i tuoi studenti a iscriversi al corso.</p>
          {linkToShare && (
            <input
              className="form__input"
              type="text"
              value={linkToShare}
              onChange={(e) => setLinkToShare(e.target.value)}
            />
          )}
        </>
      </CustomModal>
      <CustomModal
        headerText="Codice copiato"
        type="alert"
        show={showCopiedCode}
        onHide={() => setShowCopiedCode(false)}
      >
        <p>Il codice del corso è stato copiato negli appunti.</p>
      </CustomModal>
      <CustomModal
        headerText="Modifica il nome del corso"
        confirmButtonText="Modifica"
        type="confirm"
        show={showEditModal}
        onHide={() => {
          setCourseToRename(null);
          setNewCourseName("");
          setShowEditModal(false);
        }}
        onConfirm={() => {
          setShowEditModal(false);
          onRenameSubmit(newCourseName);
          setNewCourseName("");
        }}
      >
        <>
          <p>Inserisci il nuovo nome del corso.</p>
          <input
            className="form__input"
            type="text"
            onChange={(e) => setNewCourseName(e.target.value)}
            value={newCourseName}
          />
        </>
      </CustomModal>
      <CustomModal
        headerText="Elimina il corso"
        confirmButtonText="Elimina"
        type="confirm"
        show={showDeleteModal}
        onHide={() => {
          setShowDeleteModal(false);
          setCourseToDelete(null);
        }}
        onConfirm={() => {
          setShowDeleteModal(false);
          onDeleteSubmit();
        }}
      >
        <>
          <p>
            Il corso <strong>{courseToDelete?.name}</strong> verrà eliminato.
          </p>
        </>
      </CustomModal>
    </div>
  );
};

export default MyCourses;
