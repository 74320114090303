import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import UserContext from "../context/user";
import MenuPrivate from "./menu-private";
import { ROLE_TEACHER } from "../utils/constants";
import AutoLogin from "./autoLogin";

type Props = {
  component: React.ReactNode;
};

const PrivateRoute: React.FC<Props> = ({ component: Component }) => {
  const { user } = useContext(UserContext);
  const location = useLocation();

  if (user === null) {
    return <AutoLogin prevLocation={location.pathname} />;
  }
  if (user) {
    const re = new RegExp("^/richiesta_copia/(.*)");
    const match = re.exec(location.pathname);
    if (match && user.role !== ROLE_TEACHER) {
      return <Navigate to={`/catalogo/${match[1]}`} />;
    }
    return (
      <>
        <MenuPrivate />
        {Component}
      </>
    );
  } else {
    return null;
  }
};

export default PrivateRoute;
