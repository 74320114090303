//import { NavLink } from "react-router-dom";
//import { useHeaderMenu } from "../hooks/useQueryCustom";
import classNames from "classnames";

const MenuPublic = ({ className }: { className?: string }) => {
  //const { data: headerMenu } = useHeaderMenu();

  return (
    <div className={classNames("header-menu", className)}>
      {/* <div className="header-menu__bottom">
        <div className="container-md">
          <div className="row">
            <div className="col-12 ">
              <div className="d-flex justify-content-center justify-content-md-end">
                <nav id="menu-main" className="menu-main">
                  <NavLink className="menu-main__item" to={`/catalogo`}>
                    Catalogo
                  </NavLink>
                  {headerMenu?.find((i: any) => i.label === "Webinar")?.value === "SI" && (
                    <NavLink className="menu-main__item" to={`/webinar`}>
                      Webinar
                    </NavLink>
                  )}
                  {headerMenu?.find((i: any) => i.label === "Formazione")?.value === "SI" && (
                    <NavLink className="menu-main__item" to={`/formazione`}>
                      Formazione
                    </NavLink>
                  )}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default MenuPublic;
