import { NavLink } from "react-router-dom";
import { useUserProduct } from "../hooks/useQueryCustom";

const MenuPrivate = () => {
  const { data } = useUserProduct(4);

  return (
    <div className="header-menu">
      {/* <div className="header-menu__bottom header-menu__bottom--private">
        <div className="container-md">
          <div className="row">
            <div className="col-12 ">
              <div className="d-flex justify-content-center justify-content-md-end">
                <nav id="menu-main" className="menu-main">
                  {data?.myProducts.items.length > 0 && (
                    <NavLink className="menu-main__item" to={`/my-catalogo`}>
                      Il mio catalogo
                    </NavLink>
                  )}
                  {data?.myWebinars.items.length > 0 && (
                    <NavLink className="menu-main__item" to={`/my-webinar`}>
                      Webinar
                    </NavLink>
                  )}
                  {data?.myLearning.items.length > 0 && (
                    <NavLink className="menu-main__item" to={`/my-formazione`}>
                      Formazione
                    </NavLink>
                  )}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default MenuPrivate;
