import { useRef, useEffect } from "react";
import { useLocation, Path } from "react-router-dom";

const useScrollToTop = () => {
  const ref = useRef<Path>();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== ref.current?.pathname) {
      window.scrollTo(0, 0);
    }
    ref.current = location;
  }, [location]);
}

export default useScrollToTop;