import { Helmet } from "react-helmet-async";

const Seo = ({ title = "Pearson Place", description = "Pearson Place", sitename = true }) => (
  <Helmet>
    <title>
      {title} {sitename ? " | Pearson Place " : ""}
    </title>
    <meta name="description" content={description} />
  </Helmet>
);

export default Seo;
