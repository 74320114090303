type Props = {
  user: User;
};

const Greeting: React.FC<Props> = ({ user }: Props) => {
  return (
    <div className="greeting d-none d-lg-flex align-items-end">
      <span className="greeting__message">Buongiorno,</span>
      <span className="greeting__name">{user.name}</span>
    </div>
  );
};

export default Greeting;
