import { GraphQLClient } from "graphql-request";
import Cookies from "js-cookie";

const apiHost = import.meta.env.VITE_GRAPHQL_URL;
const basepath = import.meta.env.BASE_URL;
const localGraphQL = import.meta.env.VITE_LOCAL_GRAPHQL === "true";

const customFetch = (url: string, options: any) => {
  const body = JSON.parse(options.body);
  const newOptions = { ...options, method: "GET", body: null };
  const newUrl = `${basepath}mock/${body.operationName}.json`;
  return fetch(newUrl, newOptions);
};

const graphQLClient = new GraphQLClient(`${apiHost}/graphql`);

const graphQLMockClient = new GraphQLClient(`/graphql`, {
  fetch: customFetch,
});

const callApi = async (body: any): Promise<any> => {
  const response = await fetch("/graphql", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  const { data, error } = await response.json();
  if (error) {
    throw new Error("Error: " + error);
  }
  return data;
};

export class SessionExpiredError extends Error {
  constructor() {
    super("Sessione scaduta");
  }
}

const callApiQl = async (
  body: any,
  variables: any = null,
  forceMock: boolean = localGraphQL
): Promise<any> => {
  let data;

  if (forceMock) {
    data = await graphQLMockClient.request(body, variables);
  } else {
    const cid = Cookies.get("X-Pearson-userID") || "";
    try {
      data = await graphQLClient.request({
        document: body,
        variables,
        requestHeaders: {
          "X-Pearson-userID": cid,
        },
      });
    } catch (err) {
      if (variables.userId && !cid) {
        console.error("err: ", err);
        throw new SessionExpiredError();
      }
      throw err;
    }
  }

  if (!data) {
    throw new Error("GRAPH QL ERROR");
  }

  return data;
};

export { callApi, callApiQl };
