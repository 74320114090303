import { Link } from "react-router-dom";
import Icon from "./icon";

type Props = {
  path: string;
  anchor: string;
};

const Backlink = ({ path, anchor }: Props) => {
  return (
    <div className="my-3 mt-md-5 mb-md-4">
      <Link to={`/${path}`} className="backlink d-flex align-items-center">
        <Icon name="caret" className="backlink__icon" />
        <span className="ms-2">{anchor}</span>
      </Link>
    </div>
  );
};

export default Backlink;
