import FormInput from "./form-input";
import CheckboxInput from "./checkbox-input";
import Icon from "./icon";
import CustomModal from "./modal";
import { useForm } from "react-hook-form";

interface Props {
  show: boolean;
  onHide: () => void;
  onSubmit: (data: any) => void;
  onDelete?: (address: Address) => void;
  addressToEdit: Address | null;
  key?: React.Key;
}

const AddressModal: React.FC<Props> = ({
  show,
  onHide,
  onSubmit,
  addressToEdit,
  onDelete,
}: Props) => {
  const headerText = `${addressToEdit ? "Modifica" : "Aggiungi"} indirizzo`;
  const defaultValues = addressToEdit ? addressToEdit : {};
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange", defaultValues });

  return (
    <CustomModal show={show} headerText={headerText} type="alert" onHide={onHide} scrollable={true}>
      <div className="modal__scrollable">
        <form className="form py-3" onSubmit={handleSubmit(onSubmit)}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="form__label text-end me-1">* Campi obbligatori</div>
              </div>
              <div className="col-12">
                <FormInput
                  name="name"
                  label="Nome indirizzo di consegna*"
                  placeholder="Inserici un nome identificativo per questo indirizzo"
                  register={register}
                  required={true}
                  errors={errors}
                />
              </div>
              <div className="col-12 mb-2">
                <div className="form__label form__label--inline">Stato:</div>
                <span>Italia</span>
              </div>
              <div className="col-12">
                <FormInput
                  name="fullName"
                  label="Nome e Cognome*"
                  register={register}
                  required={true}
                  errors={errors}
                />
              </div>
              <div className="col-12">
                <FormInput name="near" label="Presso" register={register} errors={errors} />
              </div>
              <div className="col-12">
                <FormInput
                  name="street"
                  label="Indirizzo*"
                  placeholder="Inserici il tuo indirizzo comprensivo del numero civico"
                  register={register}
                  required={true}
                  errors={errors}
                />
              </div>
              <div className="col-12">
                <FormInput
                  name="city"
                  label="Città*"
                  register={register}
                  required={true}
                  errors={errors}
                />
              </div>
              <div className="col-12">
                <FormInput
                  name="province"
                  label="Provincia*"
                  register={register}
                  required={true}
                  errors={errors}
                />
              </div>
              <div className="col-12">
                <FormInput
                  name="cap"
                  label="CAP*"
                  register={register}
                  required={true}
                  errors={errors}
                />
              </div>
              <div className="col-12">
                <FormInput
                  name="phoneNumber"
                  label="Numero di telefono*"
                  register={register}
                  errors={errors}
                />
              </div>
              <div className="col-12 d-flex align-items-center mb-1">
                <CheckboxInput
                  name="isFavourite"
                  className="form__checkbox me-2"
                  label="Indirizzo di consegna predefinito"
                  register={register}
                  errors={errors}
                />
              </div>
              <FormInput name="addressId" type="hidden" register={register} />
              {addressToEdit && (
                <div className="col-12">
                  {!addressToEdit.isRegistrationPhase && onDelete ? (
                    <button
                      className="text-decoration-underline mb-3"
                      type="button"
                      onClick={() => onDelete(addressToEdit)}
                    >
                      <strong>Elimina indirizzo</strong>
                    </button>
                  ) : (
                    <p>
                      <strong>Questo indirizzo non può essere eliminato</strong>
                    </p>
                  )}
                </div>
              )}
              <div className="col-12">
                <button className="button button--tertiary w-100">
                  <Icon name="floppy-disk" width="25px" height="25px" /> Salva questo indirizzo
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </CustomModal>
  );
};

export default AddressModal;
