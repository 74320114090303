import { useEffect, useState } from "react";
import classNames from "classnames";

import Format from "./format";
import Session from "./session";
import Description from "./description";

type Props = {
  formats?: Format[];
  sessions?: Format[];
  description?: string;
  index?: string;
  resources?: string;
};

const Tabs: React.FC<Props> = ({ formats, sessions, description, index, resources }) => {
  const [active, setActive] = useState(0);
  useEffect(() => {
    if (!formats) {
      setActive(1);
    } else {
      setActive(0);
    }
  }, [formats]);
  return (
    <div className="tabs">
      <nav className="tabs__nav no-scrollbar">
        {formats ? (
          <button
            className={classNames("tabs__nav-item", "button--link", { active: active === 0 })}
            onClick={() => setActive(0)}
          >
            Formati disponibili
          </button>
        ) : null}
        {sessions ? (
          <button
            className={classNames("tabs__nav-item", "button--link", { active: active === 1 })}
            onClick={() => setActive(1)}
          >
            Sessioni
          </button>
        ) : null}
        {description ? (
          <button
            className={classNames("tabs__nav-item", "button--link", { active: active === 2 })}
            onClick={() => setActive(2)}
          >
            Descrizione
          </button>
        ) : null}
        {index ? (
          <button
            className={classNames("tabs__nav-item", "button--link", { active: active === 3 })}
            onClick={() => setActive(3)}
          >
            Indice
          </button>
        ) : null}
        {resources ? (
          <button
            className={classNames("tabs__nav-item", "button--link", { active: active === 4 })}
            onClick={() => setActive(4)}
          >
            Risorse
          </button>
        ) : null}
      </nav>
      <div className="tabs__list">
        {active === 0 ? (
          <div className="tabs__item">
            {formats
              ?.sort((a, b) => a.formatType - b.formatType)
              .map((format, i) => (
                <Format key={i} data={format} />
              ))}
          </div>
        ) : null}
        {active === 1 ? (
          <div className="tabs__item">
            {sessions?.map((session, i) => (
              <Session key={i} data={session} />
            ))}
          </div>
        ) : null}
        {active === 2 ? (
          <div className="tabs__item">
            {description ? <Description description={description} /> : null}
          </div>
        ) : null}
        {active === 3 ? (
          <div className="tabs__item">{index ? <Description description={index} /> : null}</div>
        ) : null}
        {active === 4 ? (
          <div className="tabs__item">
            {resources ? <Description description={resources} /> : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Tabs;
