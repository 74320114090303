import { useEffect, useRef } from "react";

const useClickOut = (flag: boolean, setFlag: (arg: boolean) => void) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (flag) {
      const listener = (event: MouseEvent) => {
        if (
          event?.target !== ref.current &&
          ref.current &&
          !ref.current.contains(event.target as Node)
        ) {
          setFlag(false);
        }
      };
      const timeout = setTimeout(() =>
        document.addEventListener("click", listener, { capture: true })
      );
      return () => {
        clearTimeout(timeout);
        document.removeEventListener("click", listener);
      };
    }
  }, [flag, setFlag]);
  return ref;
};

export default useClickOut;
