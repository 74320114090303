import classNames from "classnames";
import icons from "../assets/icons.svg";

type Props = {
  name: string;
  className?: string;
  onClick?: () => void;
  width?: string;
  height?: string;
};

const Icon: React.FC<Props> = ({ name, className, ...other }) => (
  <svg className={classNames("icon", className)} {...other}>
    <use href={`${icons}#${name}`} />
  </svg>
);

export default Icon;
