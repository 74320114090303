import { useState, useRef, useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, type Swiper as SwiperRef } from "swiper";
import Skeleton from "react-loading-skeleton";
import { PrevButton, NextButton } from "./bullettin-arrows";
import placeholders from "../utils/placeholders";
import UserContext from "../context/user";
import { useBacheca } from "../hooks/useQueryCustom";
import LoadingError from "../components/loading-error";
import dayjs from "dayjs";

const Bulletin = () => {
  const [isFirstSlide, setIsFirstSlide] = useState<boolean>(true);
  const [isLastSlide, setIsLastSlide] = useState<boolean>(false);
  const basepath = import.meta.env.BASE_URL;
  const { user } = useContext(UserContext);
  const role = user?.role as string;
  const { data: notices, isError, isFetching } = useBacheca(role);
  const swiperRef = useRef<SwiperRef>();

  return (
    <div className="bulletin">
      {notices?.length > 0 || isError ? (
        <div className="bulletin__carousel">
          <div className="container-md">
            <div className="row">
              <div id="carousel-container" className="col-12">
                <div className="bulletin__header d-flex align-items-center justify-content-between">
                  <h1 className="bulletin__title">Bacheca</h1>
                  <div className="bulletin__controls">
                    {notices &&
                    notices.length > 2 &&
                    swiperRef.current &&
                    swiperRef.current.slides.length > 1 ? (
                      <>
                        <PrevButton swiper={swiperRef.current} active={!isFirstSlide} />
                        <NextButton swiper={swiperRef.current} active={!isLastSlide} />
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            {isError ? (
              <LoadingError container={false} />
            ) : (
              <div className="row">
                <div className="col-12">
                  <Swiper
                    spaceBetween={20}
                    navigation={false}
                    pagination={true}
                    slidesPerView={1}
                    loop={false}
                    breakpoints={{
                      768: {
                        slidesPerView: 2,
                      },
                    }}
                    onSlideChange={(swiper) => {
                      const activeIndex = swiper.activeIndex;
                      setIsFirstSlide(activeIndex === 0);
                      setIsLastSlide(swiper.isEnd);
                    }}
                    onSwiper={(swiper) => (swiperRef.current = swiper)}
                    modules={[Navigation, Pagination]}
                  >
                    {(notices || placeholders(4)).map((notice: Notice | Placeholder, i: number) => (
                      <SwiperSlide key={i}>
                        <div className="bulletin-card d-flex">
                          <div className="bulletin-card__image">
                            {"placeholder" in notice ? (
                              <Skeleton width={150} height={220} />
                            ) : (
                              <img
                                src={notice.image ? notice.image : `${basepath}/ummy_image_6.png`}
                                alt={notice.title}
                              />
                            )}
                          </div>
                          <div className="bulletin-card__content w-100 d-flex flex-column">
                            <div className="bulletin-card__title">
                              {"placeholder" in notice ? <Skeleton /> : notice.title}
                            </div>
                            <div className="bulletin-card__date">
                              {"placeholder" in notice ? (
                                <Skeleton />
                              ) : (
                                dayjs(notice.datePublished).format("DD/MM/YYYY")
                              )}
                            </div>
                            {"placeholder" in notice ? (
                              <div className="bulletin-card__description">
                                <Skeleton count={3.5} />
                              </div>
                            ) : (
                              <>
                                <div
                                  className="bulletin-card__description mb-3"
                                  dangerouslySetInnerHTML={{ __html: notice.description }}
                                ></div>
                                {notice.buttonLink ? (
                                  <div className="bulletin-card__cta d-flex mt-auto align-self-end">
                                    <a
                                      href={notice.buttonLink}
                                      className="button button--secondary"
                                    >
                                      {notice?.buttonLabel || "Vai al sito"}
                                    </a>
                                  </div>
                                ) : null}
                              </>
                            )}
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}

                    <div slot="container-end">
                      <div className="swiper-pagination"></div>
                    </div>
                  </Swiper>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : isFetching ? (
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <Skeleton width={"100%"} height="200px" baseColor={"#fff"} />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Bulletin;
