import { toast } from "react-toastify";
import { callApiQl } from "./call-api";
import { gql } from "graphql-request";
import { GROUP_ID } from "./search";

export const callBigCommerce = async (token: string, isbn: string) => {
  try {
    const response = await fetch(import.meta.env.VITE_BG_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": token,
      },
      body: JSON.stringify({
        data: [
          {
            sku: isbn,
            quantity: 1,
          },
        ],
        consent: {
          allow: ["ESSENTIAL", "TARGETING", "FUNCTIONAL"],
          deny: ["ANALYTICS"],
        },
      }),
    });
    if (!response.ok) {
      const { status } = response;
      if (status === 404) {
        toast.error("Prodotto non disponibile");
      } else if (status === 500) {
        toast.error("Sessione non valida");
      }
    } else {
      const { data } = await response.json();
      if (data.checkoutUrl) {
        window.location.href = data.checkoutUrl;
      } else {
        toast.error("Servizio non disponibile");
      }
    }
  } catch (error) {
    toast.error("Servizio non disponibile");
  }
};

export const syncBigCommercePrice = async (userData: UserDataForQuery, isbn: string) => {
  const { userId } = userData;

  const variables = {
    userId,
    isbn,
  };

  const data = await callApiQl(
    gql`
      mutation synchronizePriceWithEcommerce(
        $userId: String!
        $isbn: String!
      ) {
        synchronizePriceWithEcommerce(
          userId: $userId
          isbn: $isbn
          groupId:  ${GROUP_ID}
        ) {
          statusCode
          statusMessage
        }
      }
    `,
    variables,
    false
  );
  if (data?.synchronizePriceWithEcommerce?.errors?.[0]?.message) {
    throw new Error(data?.synchronizePriceWithEcommerce.errors[0].message);
  }
  return data;
};
