import { callApiQl } from "../utils/call-api";
import { gql } from "graphql-request";

export const VOCABULARY_TYPE_ID = import.meta.env.VITE_VOCABULARY_TYPE_ID;
export const VOCABULARY_SUBJECT_ID = import.meta.env.VITE_VOCABULARY_SUBJECT_ID;
export const GROUP_ID = import.meta.env.VITE_GROUP_ID;

export const getTypeAndSubjects = async (operationName: string, forceMock?: boolean) => {
  const data = await callApiQl(
    gql`
      query ${operationName} {
        subject: taxonomyVocabularyTaxonomyCategories(taxonomyVocabularyId: ${VOCABULARY_SUBJECT_ID}, sort: "name", pageSize: 50 ){
          items{
            name,
            id
          }
        }
        ptype: taxonomyVocabularyTaxonomyCategories(taxonomyVocabularyId: ${VOCABULARY_TYPE_ID}, sort: "name", pageSize: 50 ){
          items{
            name,
            id
          }
        }
      }
      `,
    null,
    forceMock || false
  );
  return data;
};

export const search = async (key: any) => {
  //console.log("srarch", key);
  const { queryKey, pageParam = 1 } = key;

  const [operationName, searchParams]: [string, SearchParam] = queryKey;
  const { query = "", subject, product, order = "date", pageSize } = searchParams;

  const filter = [
    subject && `subjectCategory eq ${subject}`,
    product && `productCategoryName eq '${product}'`,
  ]
    .filter(Boolean)
    .join(" and ");

  const orderBy = {
    date: 'sort: "publishDate:desc"',
    titleAsc: 'sort: "productName:asc"',
    titleDesc: 'sort: "productName:desc"',
  }[order];

  const data = await callApiQl(
    gql`
      query ${operationName}  {
        pearsonProducts(search: "${query}", filter: "${filter}", groupId: ${GROUP_ID}, page: ${pageParam}, pageSize: ${pageSize}, ${orderBy}) {
          items {
            coverImage
            expirationDate
            groupId
            learningType
            link
            productAbstract
            descriptionEdition
            productCategory
            productCategoryName
            productDescription
            productId
            productName
            productSubtitle
            publishDate
            resources
            subjectCategory
            subjectCategoryName
            author {
              authorBio
              authorId
              authorName
              authorSurname
            }
            tags {
              tagId
              tagName
            }
            formats {
              price
              promotion {
                promotionId
                label
                price
              }
            }
          }
          page
          pageSize
          totalCount
        }
      }
      `,
    null,
    false
  );
  return data;
};
