import { useCallback, useMemo, Fragment } from "react";
import { useSearchParams } from "react-router-dom";
import Select, { SingleValue } from "react-select";
import Search from "../components/search";
import Card from "../components/card";
import Icon from "./icon";
import placeholders from "../utils/placeholders";
import { useSubject, useSearch } from "../hooks/useQueryCustom";

type Props = {
  type?: string;
  size?: number;
  children: React.ReactNode;
};

export interface option {
  label: string;
  value: string;
}

interface filter {
  label?: string;
  value: string;
  key: string;
}

const options: option[] = [
  {
    value: "date",
    label: "Data di pubblicazione",
  },
  {
    value: "titleAsc",
    label: "Ordine alfabetico A-Z",
  },
  {
    value: "titleDesc",
    label: "Ordine alfabetico Z-A",
  },
];

const SearchWrapper: React.FC<Props> = ({ type, size = 6, children }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: typeAndSubject } = useSubject();

  const wrapSearchParams = useMemo(() => {
    const q = searchParams.get("q");
    const types = searchParams.get("t");
    const subject = searchParams.get("s");
    const order = searchParams.get("o");

    if (q || (!type && types) || subject) {
      const params = {
        query: q || "",
        subject: Number(subject),
        product: types || type,
        order: order || "date",
        pageSize: size,
      };
      return params;
    } else {
      return null;
    }
  }, [searchParams, type, size]);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching: isFetchingSearch,
    isFetchingNextPage,
    isRefetching,
  } = useSearch(wrapSearchParams as SearchParam);

  const onSubmit = (data: any) => {
    const params = new URLSearchParams();
    if (data.q) {
      params.set("q", data.q);
    }
    if (data.types) {
      params.set("t", data.types);
    }
    if (data.subject) {
      params.set("s", data.subject);
    }
    if (data.q || data.types || data.subject) {
      setSearchParams(params);
    }
  };

  const onOrderChange = (data: SingleValue<option>) => {
    const params = new URLSearchParams(searchParams);
    const q = params.get("q");
    const types = params.get("t");
    const subject = params.get("s");
    const order = data?.value;

    if ((q || types || subject) && order) {
      params.set("o", order);
      setSearchParams(params);
    }
  };

  const buttonLabel = useCallback(
    (key: string, value: string) => {
      switch (key) {
        case "q":
          return value;
        case "t":
          const type = typeAndSubject?.mappedTypes.find((item: option) => {
            return item.value === value;
          });
          return type?.label;
        case "s":
          const subject = typeAndSubject?.mappedSubjects.find(
            (item: option) => item.value === value
          );
          return subject?.label;
        default:
          return "";
      }
    },
    [typeAndSubject]
  );

  const removeParam = (key: string) => {
    const params = new URLSearchParams(searchParams);
    params.delete(key);
    setSearchParams(params);
  };

  const hasSearch = useMemo(() => {
    const q = searchParams.get("q");
    const types = searchParams.get("t");
    const subject = searchParams.get("s");
    return q || types || subject;
  }, [searchParams]);

  const activeFilters = useMemo(() => {
    const validSearchKey = ["t", "s", "q"];
    const filters: filter[] = [];
    searchParams.forEach((value, key) => {
      if (validSearchKey.includes(key))
        filters.push({ value, key, label: buttonLabel(key, value) });
    });
    return filters;
  }, [searchParams, buttonLabel]);

  const sortOption = useMemo(() => {
    const orderItem = activeFilters.find((item) => item.key === "o");
    return (orderItem && options.find((option) => option.value === orderItem.value)) || options[0];
  }, [activeFilters]);

  return (
    <>
      {typeAndSubject?.mappedSubjects && (
        <Search
          subjects={typeAndSubject?.mappedSubjects}
          types={type ? undefined : typeAndSubject?.mappedTypes}
          onSubmit={onSubmit}
        />
      )}
      {hasSearch ? (
        <div className="container-md">
          <div className="row">
            <div className="col-12 ">
              {data?.pages[0] && (
                <div className="search-wrapper">
                  <div className="row">
                    <div className="d-flex align-items-center justify-content-between mt-4">
                      <span className="search-wrapper__results">
                        {data.pages[0].pearsonProducts.totalCount} Risultati
                      </span>
                      <Select
                        classNamePrefix="select"
                        className="select-order"
                        options={options}
                        onChange={onOrderChange}
                        placeholder="Ordinamento"
                        defaultValue={sortOption}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="search-tags my-2">
                      {activeFilters
                        .filter((p: any) => p.key !== "o")
                        .map((item: any) => (
                          <button
                            key={item.key}
                            className="button button--secondary"
                            onClick={() => removeParam(item.key)}
                          >
                            <span>{item.label}</span>
                            <Icon name="close" width="14px" height="12px" />
                          </button>
                        ))}
                    </div>
                  </div>
                </div>
              )}
              {data && (
                <div className="row">
                  {data?.pages.map((group, i: number) => (
                    <Fragment key={i}>
                      {group.pearsonProducts.items.map((product: Product, j: number) => (
                        <div key={j} className="col-12 col-md-6">
                          <Card data={product} type={product.productCategoryName.toLowerCase()} />
                        </div>
                      ))}
                    </Fragment>
                  ))}
                </div>
              )}

              {isFetchingSearch && !isRefetching && (
                <div className="row">
                  {placeholders(size).map((product: Product | Placeholder, i: number) => (
                    <div key={i} className="col-12 col-md-6">
                      <Card
                        data={product}
                        type={!("placeholder" in product) ? product.productCategoryName : ""}
                      />
                    </div>
                  ))}
                </div>
              )}

              <div className="row">
                <div className="col-12 text-center mt-4 mt-md-1 mb-5">
                  {hasNextPage && !isFetchingNextPage && (
                    <button onClick={() => fetchNextPage()} className="button button--secondary">
                      Carica di più
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default SearchWrapper;
