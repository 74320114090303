import image from "../assets/image_404.jpg";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  const title = "Errore 404";
  const description =
    "Lorem ipsum dolor sit amet. Et rerum dolor sit molestias soluta aut maiores quia et quos doloremque non omnis molestiae quo unde aliquid.";
  const imageAlt = "Errore 404";
  return (
    <div className="error-page">
      <img className="error-page__image d-none d-md-block" src={image} alt={imageAlt} />
      <div className="container-md">
        <div className="row">
          <div className="col-12 ">
            <div className="d-md-flex">
              <div className="error-page__content d-flex flex-column ms-md-auto">
                <h1 className="error-page__title">{title}</h1>
                <div className="error-page__description">{description}</div>
                <div className="mt-auto">
                  <Link className="button button--w-xs-100" to="/">
                    Vai alla Home Page
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
