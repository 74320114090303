import MyProductsWidget from "../components/my-products-widget";
import { useMemo, useState } from "react";
import LoadingError from "../components/loading-error";
import { useUserProductByCategory } from "../hooks/useQueryCustom";
import placeholders from "../utils/placeholders";
import { useNavigate, useParams } from "react-router-dom";

const MyProducts = () => {
  const [createCourseId, setCreateCourseId] = useState<number | null>(null);
  const [enrollCourseId, setEnrollCourseId] = useState<number | null>(null);
  const { productId } = useParams<{ productId: string }>();
  const selectedProductId = productId ? Number(productId) : null;
  const navigate = useNavigate();
  const {
    data: myProducts,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isError,
    error,
  } = useUserProductByCategory("getProductsByUserId", "Prodotto", 12);

  const allItems = useMemo(() => {
    const all = myProducts?.pages.reduce((prev, current) => {
      return prev.concat(current.pearsonProductsByUserId?.items);
    }, []);
    return all;
  }, [myProducts]);

  const setSelectedProductId = (id: number | null) => {
    if (id === null) {
      navigate("/my-catalogo");
    } else navigate(`/my-catalogo/${id}`);
  };

  const selectedProduct = useMemo(() => {
    return allItems?.find((i: MyProduct) => i?.product?.productId === selectedProductId) || null;
  }, [allItems, selectedProductId]);

  const selectProduct = (myProduct: MyProduct) => {
    setSelectedProductId(myProduct?.product?.productId);
    setCreateCourseId(myProduct?.product?.productId);
    setEnrollCourseId(myProduct?.product?.productId);
  };

  const handleCreateCourse = (myProduct: MyProduct) => {
    setSelectedProductId(myProduct?.product?.productId);
    setCreateCourseId(myProduct?.product?.productId);
  };

  const handleEnrollCourse = (myProduct: MyProduct) => {
    setSelectedProductId(myProduct?.product?.productId);
    setEnrollCourseId(myProduct?.product.productId);
  };

  return (
    <div className="section">
      <div className="container-md">
        <div className="row">
          <div className="col-12">
            <h2 className="page__section-title mb-4">Tutti i tuoi prodotti</h2>
          </div>
          {isError ? (
            <LoadingError error={error} />
          ) : (
            <>
              {allItems?.length === 0 && (
                <div className="col-12">
                  <h3 className="page__section--no-product">Nessun prodotto abilitato</h3>
                </div>
              )}
              <MyProductsWidget
                myProducts={allItems || placeholders(4)}
                selectedProduct={selectedProduct}
                handleBack={() => setSelectedProductId(null)}
                selectProduct={selectProduct}
                showCreateCourse={selectedProductId === createCourseId}
                showEnrollCourse={selectedProductId === enrollCourseId}
                handleEnrollCourse={handleEnrollCourse}
                handleCreateCourseCancel={() => setCreateCourseId(null)}
                handleEnrollCourseCancel={() => setSelectedProductId(null)}
                handleCreateCourse={handleCreateCourse}
              />
            </>
          )}
          {isFetchingNextPage && (
            <MyProductsWidget
              myProducts={placeholders(4)}
              selectedProduct={null}
              handleBack={() => setSelectedProductId(null)}
              selectProduct={selectProduct}
              showCreateCourse={selectedProductId === createCourseId}
              showEnrollCourse={selectedProductId === enrollCourseId}
              handleEnrollCourse={handleEnrollCourse}
              handleCreateCourseCancel={() => setCreateCourseId(null)}
              handleEnrollCourseCancel={() => setSelectedProductId(null)}
              handleCreateCourse={handleCreateCourse}
            />
          )}
          {hasNextPage && !isFetchingNextPage && !selectedProduct && (
            <div className="row">
              <div className="col-12 text-center mt-4 mb-5">
                <button onClick={() => fetchNextPage()} className="button button--secondary">
                  Carica di più
                </button>
              </div>
            </div>
          )}
          {/* <div className="related">
            <h2 className="related__title">Potrebbe interessarti</h2>
            <div className="related__buttons">
              <Link to="/webinar" className="button button--secondary button--rounded">
                Webinar
              </Link>
              <Link to="/formazione" className="button button--secondary button--rounded">
                Formazione
              </Link>
            </div>
          </div> */}
          {/*  <MyHelp /> */}
        </div>
      </div>
    </div>
  );
};

export default MyProducts;
