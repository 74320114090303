import { useForm, Controller } from "react-hook-form";
import Icon from "../components/icon";
import UserContext from "../context/user";
import { useContext, useMemo } from "react";
import { useProvince, useUniversity } from "../hooks/useQueryCustom";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { getSettori } from "../utils/utility";
import { ROLE_STUDENT, ROLE_TEACHER, ROLE_NONE } from "../utils/constants";

const UserInfo = () => {
  const { user, updateUser } = useContext(UserContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const selectRuoli = [
    { label: "Studente universitario", value: ROLE_STUDENT },
    { label: "Docente universitario", value: ROLE_TEACHER },
    { label: "Studente/ Genitore/ Docente scuola", value: ROLE_NONE },
  ];
  const defaultValues = {
    ...user,
    provincia: user?.university?.province || "",
    universityId: user?.university?.universityId || 0,
  };
  const openSanoma = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (window?.piSession) {
      window.piSession.logout();
    }
    window.location.href = "https://place.sanomaitalia.it";
  };

  const settori = useMemo(() => {
    return getSettori();
  }, []);

  const { register, handleSubmit, watch, control, getValues } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const watchRole = watch("role");
  const watchProvincia = watch("provincia");
  const onSubmit = async (variables: any) => {
    toast.promise(updateUser(variables), {
      pending: "Aggiornamento in corso",
      success: {
        render() {
          return "Aggiornamento completato.";
        },
        onClose: () => {
          if (state?.from) {
            navigate(state.from);
          } else {
            navigate("/my-home");
          }
        },
      },
      error: "Servizio non disponibile",
    });
  };

  const { data: province } = useProvince();

  const selectedProvince = useMemo(() => {
    if (watchProvincia) {
      return getValues("provincia");
    } else {
      return "";
    }
  }, [watchProvincia, getValues]);

  const { data: university } = useUniversity(selectedProvince);

  return (
    <div className="support">
      <div className="page__header page__header--private">
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <div className="page__header-text d-flex flex-column justify-content-center">
                <h1 className="page__header-title">Completa il profilo</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="container-md">
          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
              <form className="form py-3" name="support" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-12">
                    <div className="form__label text-end me-1">* Campi obbligatori</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form__label">Nome</div>
                    <input
                      type="text"
                      disabled
                      className="form__input"
                      placeholder=""
                      {...register("name")}
                    />
                  </div>
                  <div className="col-6">
                    <div className="form__label">Cognome</div>
                    <input
                      type="text"
                      disabled
                      className="form__input"
                      placeholder=""
                      {...register("surname")}
                    />
                  </div>
                  <div className="col-6">
                    <div className="form__label">Email</div>
                    <input
                      type="text"
                      disabled
                      className="form__input"
                      placeholder=""
                      {...register("email")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6  mb-4">
                    <div className="form__label">Ruolo*</div>
                    <Controller
                      name="role"
                      rules={{ required: true }}
                      control={control}
                      render={({ field: { onChange, value, ...other } }) => (
                        <Select
                          isDisabled={defaultValues.role !== ""}
                          classNamePrefix="select"
                          className="select-input"
                          options={selectRuoli}
                          placeholder="Ruolo"
                          value={selectRuoli.find((i) => i.value === value)}
                          onChange={(e) => onChange(e?.value)}
                          {...other}
                        />
                      )}
                    />
                  </div>
                </div>
                {watchRole && watchRole !== ROLE_NONE && province && (
                  <>
                    <div className="row">
                      <div className="col-6">
                        <div className="form__label">Provincia*</div>
                        <Controller
                          name="provincia"
                          rules={{ required: true }}
                          control={control}
                          render={({ field: { onChange, value, ...other } }) => (
                            <Select
                              classNamePrefix="select"
                              className="select-input"
                              options={province}
                              placeholder="Provincia"
                              value={province.find((i: any) => i.value === value)}
                              onChange={(e) => onChange(e?.value)}
                              {...other}
                            />
                          )}
                        />
                      </div>

                      <div className="col-6">
                        <div className="form__label">Università*</div>
                        <Controller
                          name="universityId"
                          rules={{ required: true }}
                          control={control}
                          render={({ field: { onChange, value, ...other } }) => (
                            <Select
                              classNamePrefix="select"
                              className="select-input"
                              options={university}
                              placeholder="Seleziona l'università"
                              value={university?.find((i: any) => i.value === value) || null}
                              onChange={(e) => onChange(e?.value)}
                              {...other}
                            />
                          )}
                        />
                      </div>
                    </div>

                    {watchRole === ROLE_TEACHER && (
                      <>
                        <div className="row">
                          <div className="col-6">
                            <div className="form__label">Dipartimento*</div>
                            <input
                              type="text"
                              maxLength={75}
                              className="form__input"
                              placeholder=""
                              {...register("department", { required: true })}
                            />
                          </div>

                          <div className="col-6">
                            <div className="form__label">Settore*</div>
                            <Controller
                              name="sector"
                              rules={{ required: true }}
                              control={control}
                              render={({ field: { onChange, value, ...other } }) => (
                                <Select
                                  classNamePrefix="select"
                                  className="select-input"
                                  options={settori}
                                  placeholder="Settore"
                                  value={settori?.find((i: any) => i.value === value) || null}
                                  onChange={(e) => {
                                    onChange(e?.value);
                                  }}
                                  {...other}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="form__label">CAP*</div>
                            <input
                              type="text"
                              className="form__input"
                              placeholder=""
                              {...register("cap", { required: true })}
                            />
                          </div>

                          <div className="col-6">
                            <div className="form__label">Città*</div>
                            <input
                              type="text"
                              maxLength={75}
                              className="form__input"
                              placeholder=""
                              {...register("city", { required: true })}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="form__label">Indirizzo università*</div>
                            <input
                              type="text"
                              maxLength={75}
                              className="form__input"
                              placeholder=""
                              {...register("universityAddress", { required: true })}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {watchRole === ROLE_STUDENT && (
                      <div className="row">
                        <div className="col-6">
                          <div className="form__label">Corso di laurea</div>
                          <input
                            type="text"
                            maxLength={75}
                            className="form__input"
                            placeholder=""
                            {...register("degreeCourse")}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
                {watchRole === ROLE_NONE && (
                  <div className="row">
                    <div className="col-12">
                      <p>
                        Per attivare un prodotto digitale Pearson per la scuola collegati a{" "}
                        <a
                          title="SanomaItalia"
                          onClick={(e) => openSanoma(e)}
                          href="https://place.sanomaitalia.it"
                        >
                          My Place Sanoma
                        </a>
                        . <br />
                        Per i futuri accessi ricorda di utilizzare il link{" "}
                        <a
                          title="SanomaItalia"
                          onClick={(e) => openSanoma(e)}
                          href="https://place.sanomaitalia.it"
                        >
                          place.sanomaitalia.it
                        </a>
                      </p>
                      <p>
                        Se invece vuoi attivare un prodotto <strong>universitario</strong>,
                        seleziona un ruolo differente.
                      </p>
                    </div>
                  </div>
                )}
                <div className="row mt-5">
                  <div className="col-12">
                    {watchRole !== ROLE_NONE && (
                      <button
                        disabled={!watchRole ? true : false}
                        className="button button--tertiary"
                      >
                        <Icon name="floppy-disk" width="25px" height="25px" /> Salva
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserInfo;
