import Icon from "./icon";

const LoadingError: React.FC<{ container?: boolean; className?: string; error?: any }> = ({
  container = true,
  className = "",
  // error,
}) => {
  type Props = {
    condition: boolean;
    children: React.ReactElement;
  };

  const WrapWithContainer = ({ condition, children }: Props) => {
    return condition ? (
      <div className="container-md">
        <div className="row">
          <div className="col-12">{children}</div>
        </div>
      </div>
    ) : (
      children
    );
  };

  return (
    <WrapWithContainer condition={container}>
      <div className={`error-block ${className}`}>
        <div className="d-flex py-3">
          <Icon name="error" height="30" width="40" />
          <div>
            <strong>Queste informazioni non sono al momento disponibili.</strong>
            <br />
            Prova a ricaricare la pagina o a ricollegarti più tardi
          </div>
        </div>
        <div>
          <span className="loading-spinner" />
        </div>
      </div>
    </WrapWithContainer>
  );
};

export default LoadingError;
