import CardContainer from "../components/card-container";
import Slider from "../components/slider";
import SearchWrapper from "../components/search-wrapper";
import { useProducts } from "../hooks/useQueryCustom";
import MenuPublic from "../components/menu-public";
import Seo from "../components/seo";

const Products = () => {
  const prodotti = useProducts("getProduct", "prodotto", 6);

  return (
    <>
      <Seo title="Catalogo" />
      <MenuPublic />
      <SearchWrapper type="prodotto">
        <Slider type="prodotto" />
        <section className="section">
          {prodotti && (
            <CardContainer
              type="prodotto"
              result={prodotti}
              label={"Catalogo"}
              more={true}
              size={6}
            />
          )}
        </section>
      </SearchWrapper>
    </>
  );
};

export default Products;
