import Social from "./social";
import FooterMenuContainer from "./footer-menu-container";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { isPrivateRoute } from "../utils/utility";

const Footer = () => {
  const year = new Date().getFullYear();
  const piva = "13126210965";
  const location = useLocation();
  return (
    <footer className="footer">
      <div
        className={classNames("footer__top", {
          "footer__top--private": isPrivateRoute(location.pathname),
        })}
      >
        <div className="container-md">
          <div className="row">
            <div className="col-12 ">
              <Social />
            </div>
          </div>
        </div>
      </div>
      <FooterMenuContainer />
      <div className="footer__bottom">
        <div className="container-md">
          <div className="row">
            <div className="col-12 ">
              <div className="d-flex flex-column-reverse flex-md-row justify-content-between">
                <div className="footer__copyright">
                  {`Pearson Benelux B.V., Sede Secondaria Italiana \u00a9 ${year} , tutti i diritti riservati. P.I.  ${piva}`}
                </div>
                <div className="d-flex flex-column flex-md-row align-items-md-center">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="footer__link"
                    href="https://www.linkpearson.it/25S7BX4KYF"
                  >
                    Dati societari
                  </a>
                  <a
                    className="footer__link"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkpearson.it/FC3C8D6B"
                  >
                    Privacy Policy
                  </a>
                  <a
                    className="footer__link"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkpearson.it/VPW2I5J46A"
                  >
                    Cookie Policy
                  </a>
                  <a
                    className="footer__link"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkpearson.it/6535DCD1"
                  >
                    Termini e condizioni d'uso
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
