import classNames from "classnames";
import { type Swiper } from "swiper";
import Icon from "./icon";

type Props = {
  active: boolean;
  swiper?: Swiper;
};

const PrevButton = ({ swiper, active }: Props) => {
  return (
    <button onClick={() => swiper?.slidePrev()}>
      <Icon
        className={classNames("bulletin__control me-1", { "bulletin__control--active": active })}
        name="slider-arrow-left"
        width="38px"
        height="22px"
      />
    </button>
  );
};

const NextButton = ({ swiper, active }: Props) => {
  return (
    <button onClick={() => swiper?.slideNext()}>
      <Icon
        className={classNames("bulletin__control ms-1", { "bulletin__control--active": active })}
        name="slider-arrow-right"
        width="38px"
        height="22px"
      />
    </button>
  );
};

export { PrevButton, NextButton };
