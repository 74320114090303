import { callApiQl } from "../utils/call-api";
import { gql } from "graphql-request";
import { GROUP_ID } from "./search";

export const getIESUser = async (identityId: string, token: string) => {
  const body = { userId: identityId, token };
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };
  const url = `/api/user-info`;
  try {
    const response = await fetch(url, options);
    const data = await response.json();
    //console.log(data);
    return data;
  } catch (err) {
    console.error("err: ", err);
    throw new Error("IES ERROR");
  }
};

export const updatePearsonUser = async (variables: {
  userId: string;
  email: string;
  name: string;
  surname: string;
  universityId: 0;
}) => {
  const data = await callApiQl(
    gql`
        mutation updatePearsonUserByUserId(
          $userId: String!
          $email: String!
          $name: String!
          $surname: String!
          $role: String
          $city: String
          $universityId: Long!
          $degreeCourse: String
          $department: String
          $sector: String
          $universityAddress: String
          $cap: String
        ) {
          updatePearsonUserByUserId(
            userId: $userId
            pearsonUserAuth: {
              email: $email
              groupId: ${GROUP_ID}
              name: $name
              surname: $surname
              role: $role
              universityId: $universityId
              degreeCourse: $degreeCourse
              department: $department
              cap: $cap
              city: $city
              sector: $sector
              universityAddress: $universityAddress
            }
          ) {
            userId
            email
            groupId
            lastLoginDate
            enrollmentDate
            name
            surname
            degreeCourse
            department
            cap
            city
            sector
            role
            universityAddress
            university {
              universityId
              province
            }
            error{
              statusCode,
              statusMessage
            }
          }
        }
      `,
    variables,
    false
  );
  if (data?.updatePearsonUserByUserId?.error?.statusMessage === "duplicate-user-email") {
    const result = {
      ...variables,
      duplicate: true,
    };
    return result;
    //throw new Error("duplicate-user-email");
  }
  //console.log(data);
  return data.updatePearsonUserByUserId;
};

export const productActivation = async (codeId: string, userData: UserDataForQuery) => {
  const { userId, userIdImp } = userData;
  const variables = {
    userId,
    userIdImp,
    codeId,
  };

  const data = await callApiQl(
    gql`
      mutation pearsonUserByUserId($userId: String!, $userIdImp: String, $codeId: String!) {
        createPearsonActivationProductByCodeId(
          userId: $userId
          userIdImp: $userIdImp
          codeId: $codeId
        ) {
          assetId
          messageResult
        }
      }
    `,
    variables,
    false
  );
  //console.log(data);
  return data;
};

export const changePearsonUser = async (ticket: string, userData: UserDataForQuery) => {
  const { userId, userEmail } = userData;
  const variables = {
    userId,
    ticket,
    email: userEmail,
  };

  const data = await callApiQl(
    gql`
      mutation pearsonValidationTicketByUser($email: String!, $userId: String!, $ticket: String!) {
        createPearsonValidationTicketByUser(
          pearsonTicketUser: {
            email: $email
            groupId: ${GROUP_ID}
            userId: $userId
            ticket: $ticket
          }
        ) {
          userId
          email
          name
          surname
          role
          lastLoginDate
          university{
            name
            province
            universityId
          },
          department,
          sector,
          cap,
          degreeCourse,
          universityAddress,
          city,
          error{
              statusCode,
              statusMessage
          }
        }
      }
    `,
    variables,
    false
  );
  console.log(data);
  if (data?.createPearsonValidationTicketByUser?.error?.statusCode === "500") {
    throw new Error(data?.createPearsonValidationTicketByUser?.error?.statusMessage);
  }
  return data.createPearsonValidationTicketByUser;
};

export const getNewToken = async (userData: UserDataForQuery) => {
  const { userId, userEmail } = userData;
  const variables = {
    userId,
    email: userEmail,
  };

  const data = await callApiQl(
    gql`
      mutation createPearsonCreationTicketByUser($email: String!, $userId: String!) {
        createPearsonCreationTicketByUser(
          pearsonTicketUser: {
            email: $email
            groupId: ${GROUP_ID}
            userId: $userId
          }
        ) {
          statusCode
          statusMessage
        }
      }
    `,
    variables,
    false
  );

  if (data?.createPearsonValidationTicketByUser?.error?.statusCode === "500") {
    throw new Error(data?.createPearsonValidationTicketByUser?.error?.statusMessage);
  }
  return data.createPearsonValidationTicketByUser;
};

export const getPearsonUser = async (variables: any) => {
  const data = await callApiQl(
    gql`
      query pearsonUserByUserId($userId: String!, $userIdImp: String) {
        pearsonUserByUserId(userId: $userId, userIdImp: $userIdImp) {
          userId
          email
          name
          surname
          degreeCourse
          department
          cap
          sector
          role
          lastLoginDate
          universityAddress
          city
          university {
            universityId
            province
            name
          }
        }
      }
    `,
    variables,
    false
  );
  //console.log(data.pearsonUserByUserId);
  return data.pearsonUserByUserId;
};

export const getProvince = async () => {
  const data = await callApiQl(
    gql`
        query pearsonUniversityByProvince {
          pearsonListProvince(groupId: ${GROUP_ID}) {
            items {
              value: province
              label: province
            }
          }
        }
      `,
    null,
    false
  );
  return data.pearsonListProvince;
};

export const getUniversity = async (province: string) => {
  const data = await callApiQl(
    gql`
        query pearsonUniversityByProvince($province: String!) {
          pearsonUniversityByProvince(groupId: ${GROUP_ID}, province: $province) {
            items {
              value: universityId
              label: name
            }
          }
        }
      `,
    { province },
    false
  );
  //console.log(data);
  return data.pearsonUniversityByProvince;
};

export const getConsultant = async (userId: string) => {
  const data = await callApiQl(
    gql`
      query pearsonConsultantByUserId($userId: String!) {
        pearsonConsultantByUserId(userId: $userId) {
          email
          fullName
          phoneNumber
        }
      }
    `,
    { userId },
    false
  );
  return data;
};

export const addConsultantContact = async (userId: string, messageResult: string) => {
  const variables = {
    userId,
    messageResult,
  };

  const data = await callApiQl(
    gql`
      mutation createPearsonConsultantContantByUserId($userId: String!, $messageResult: String!) {
        createPearsonConsultantContantByUserId(
          userId: $userId
          pearsonMessage: { messageResult: $messageResult }
        ) {
          messageResult
          assetId
        }
      }
    `,
    variables,
    false
  );
  return data;
};
