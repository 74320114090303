import { Fragment } from "react";
import Icon from "./icon";
import MyCourses from "./my-courses";
import MyEnroll from "./my-enroll";
import Skeleton from "react-loading-skeleton";
import { ROLE_TEACHER, ROLE_STUDENT } from "../utils/constants";

type Props = {
  myProduct: MyProduct | Placeholder;
  handleBack: () => void;
  showCreateCourse: boolean;
  showEnrollCourse: boolean;
  onCreateCourseCancel: () => void;
  onEnrollCourseCancel: () => void;
  onCreateCourse: (myProduct: MyProduct) => void;
};

const MyProductExpanded = ({
  myProduct,
  handleBack,
  showCreateCourse,
  showEnrollCourse,
  onCreateCourseCancel,
  onEnrollCourseCancel,
}: Props) => {
  return (
    <div className="my-product-expanded">
      {"placeholder" in myProduct ? (
        <Skeleton count={3.5} />
      ) : (
        <>
          <div className="row d-block d-md-none">
            <div className="my-product-expanded__back d-flex align-items-center justify-content-between">
              <button
                onClick={handleBack}
                className="my-product-expanded__link d-flex align-items-center"
              >
                <Icon className="me-1" name="slider-arrow-left" width="18px" height="12px" />
                <span>Torna ai tuoi prodotti</span>
              </button>
              <button onClick={handleBack}>
                <Icon name="close" width="17px" height="17px" />
              </button>
            </div>
          </div>
          <div className="row mb-4">
            <div className="d-flex col-md-6">
              <div className="my-product__image">
                <img src={myProduct?.product?.coverImage} alt={myProduct?.product?.productName} />
              </div>
              <div className="my-product__content d-flex flex-column ms-4 w-100">
                <h3 className="my-product__title">{myProduct?.product?.productName}</h3>
                <h4 className="my-product__subtitle">{myProduct?.product?.productSubtitle}</h4>
                <div className="my-product__author">
                  {myProduct?.product?.author
                    ? myProduct?.product?.author?.map((author: Author, i: number) => (
                        <Fragment key={i}>
                          {author.authorName} {author.authorSurname}
                          {i < myProduct.product.author.length - 1 ? <span> - </span> : ""}
                        </Fragment>
                      ))
                    : null}
                </div>
              </div>
            </div>
            <div className="col-md-6 d-none d-md-block">
              <button
                onClick={handleBack}
                className="my-product-expanded__link d-flex align-items-center ms-md-auto mt-2"
              >
                <Icon className="me-1" name="slider-arrow-left" width="18px" height="12px" />
                <span>Torna ai tuoi prodotti</span>
              </button>
            </div>
          </div>
          <>
            {myProduct?.roleName === ROLE_STUDENT && (
              <MyEnroll
                product={myProduct}
                showEnrollCourse={showEnrollCourse}
                onEnrollCourseCancel={onEnrollCourseCancel}
                handleBack={handleBack}
              />
            )}
            {myProduct?.roleName === ROLE_TEACHER && (
              <MyCourses
                myProduct={myProduct}
                showCreateCourse={showCreateCourse}
                onCreateCourseCancel={onCreateCourseCancel}
              />
            )}
          </>
        </>
      )}
    </div>
  );
};

export default MyProductExpanded;
