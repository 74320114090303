// import { GraphQLClient, gql } from "graphql-request";
import { HelmetProvider } from "react-helmet-async";
import { Routes, Route } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import Learning from "./routes/learning";
import MyLearning from "./routes/my-learning";
import Home from "./routes/home";
import ErrorPage from "./routes/error-page";
import ProductDetail from "./routes/product-detail";
import Products from "./routes/products";
import MyProducts from "./routes/my-products";
import Webinar from "./routes/webinar";
import MyWebinar from "./routes/my-webinar";
import WebinarDetail from "./routes/webinar-detail";
import LearningDetail from "./routes/learning-detail";
import CopyRequest from "./routes/copy-request";
import AddressSelect from "./routes/address-select";
import Help from "./routes/help";
import useScrollToTop from "./hooks/useScrollToTop";
import MyHome from "./routes/my-home";
import Consultant from "./routes/consultant";
import Activation from "./routes/activation";
import UserInfo from "./routes/user-info";
import { UserProvider } from "./context/user";
import PrivateRoute from "./components/private-route";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import ChangeUser from "./routes/change-user";

const queryClient = new QueryClient();
queryClient.setDefaultOptions({
  queries: {
    retry: 1,
    refetchOnWindowFocus: false,
    staleTime: 30 * 60 * 1000, // 30 min
    cacheTime: 8 * 60 * 60 * 1000, // 8h
  },
});

function App() {
  useScrollToTop();

  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <HelmetProvider>
          <div className="app">
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/catalogo" element={<Products />} />
              <Route path="/user-info" element={<PrivateRoute component={<UserInfo />} />} />
              <Route path="/my-catalogo" element={<PrivateRoute component={<MyProducts />} />} />
              <Route
                path="/my-catalogo/:productId"
                element={<PrivateRoute component={<MyProducts />} />}
              />
              <Route path="/webinar" element={<Webinar />} />
              <Route path="/my-webinar" element={<PrivateRoute component={<MyWebinar />} />} />
              <Route path="/formazione" element={<Learning />} />
              <Route path="/my-formazione" element={<PrivateRoute component={<MyLearning />} />} />
              <Route path="/catalogo/:productId" element={<ProductDetail />} />
              <Route path="/webinar/:webinarId" element={<WebinarDetail />} />
              <Route path="/formazione/:learningId" element={<LearningDetail />} />
              <Route
                path="/richiesta_copia/:productId"
                element={<PrivateRoute component={<CopyRequest />} />}
              />
              <Route path="/scelta_indirizzo" element={<AddressSelect />} />
              <Route path="/help" element={<Help />} />
              {/* <Route path="/my-help" element={<PrivateRoute component={<MyHelp />} />} /> */}
              <Route path="/my-home" element={<PrivateRoute component={<MyHome />} />} />
              <Route
                path="/contatta-consulente"
                element={<PrivateRoute component={<Consultant />} />}
              />
              <Route
                path="/attiva-prodotto"
                element={<PrivateRoute component={<Activation />} />}
              />
              <Route path="/cambia-utente" element={<PrivateRoute component={<ChangeUser />} />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
            <Footer />
          </div>
        </HelmetProvider>
      </UserProvider>
      <ToastContainer autoClose={2000} position="bottom-center" />
    </QueryClientProvider>
  );
}

export default App;
