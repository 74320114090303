import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import Icon from "./icon";
import { option } from "./search-wrapper";

type Props = {
  onSubmit: (data: any) => void;
  subjects: option[];
  types?: option[];
};

const Search = ({ subjects, types, onSubmit }: Props) => {
  const [open, setOpen] = useState(false);
  const handleChange = () => setOpen(!open);

  const disableTypeSearch: boolean = false;

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { isSubmitSuccessful },
  } = useForm();

  useEffect(() => {
    reset({
      q: "",
      types: "",
      subject: "",
    });
  }, [reset, isSubmitSuccessful]);

  return (
    <form className="search" onSubmit={handleSubmit(onSubmit)}>
      <div className="container-md">
        <div className="row">
          <div className="">
            <div
              className="search__header d-flex d-md-none justify-content-between py-3"
              onClick={handleChange}
            >
              <div className="search__label">Cerca nel catalogo</div>
              <Icon name="caret" className="search__icon--dropdown" height="14px" width="14px" />
            </div>
            <div className={`search__body flex-column flex-md-row ${open ? "open" : ""}`}>
              <input
                className="search__item search__input flex-grow-1"
                type="text"
                placeholder="Cerca per titoli, autore o ISBN"
                aria-label="termine di ricerca"
                {...register("q")}
              />

              {types && disableTypeSearch && (
                <Controller
                  name="types"
                  control={control}
                  render={({ field: { onChange, value, ...other } }) => (
                    <Select
                      classNamePrefix="select"
                      className="search__item select-filter"
                      options={types}
                      aria-label="Tipologia"
                      placeholder="Prodotti"
                      value={types?.find((type) => type.value === value)}
                      onChange={(e) => onChange(e?.value)}
                      {...other}
                    />
                  )}
                />
              )}

              <Controller
                name="subject"
                control={control}
                render={({ field: { onChange, value, ...other } }) => (
                  <Select
                    classNamePrefix="select"
                    className="search__item select-filter"
                    options={subjects}
                    aria-label="Materia"
                    placeholder="Materia"
                    value={subjects.find((type) => type.value === value)}
                    onChange={(e) => onChange(e?.value)}
                    {...other}
                  />
                )}
              />

              <button className="search__button button">
                <Icon className="icon" name="search" width="20px" height="20px" />
                <span>Cerca</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Search;
