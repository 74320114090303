import { FieldErrors, UseFormRegister } from "react-hook-form";

type Props = {
  name: string;
  label: string;
  className: string;
  register: UseFormRegister<any>;
  errors?: FieldErrors;
  required?: boolean;
};

const CheckboxInput: React.FC<Props> = ({
  name,
  className,
  label,
  register,
  required = false,
  errors,
}: Props) => {
  return (
    <div className="form">
      <label className="form__checkbox-label">
        <input type="checkbox" className={className} {...register(name, { required: required })} />
        <span>{label}</span>
      </label>
      {errors && errors[name]?.type === "required" && (
        <p className="form__error" role="alert">
          Questo campo è richiesto
        </p>
      )}
    </div>
  );
};

export default CheckboxInput;
