import { useEffect, useContext } from "react";
import UserContext from "../context/user";

type Props = {
  prevLocation: string;
};

const AutoLogin = ({ prevLocation }: Props) => {
  const { user, login } = useContext(UserContext);
  useEffect(() => {
    if (user === null && prevLocation) {
      const returUrl = new URL(prevLocation, window.location.origin);
      console.log("returUrl", returUrl.toString());
      login(returUrl.toString());
    }
  }, [user, login, prevLocation]);

  return (
    <div className="page__content">
      <div className="container-md">
        <div className="py-5 text-center">
          Per accedere a questa pagina è necessario effettuare il login
        </div>
      </div>
    </div>
  );
};

export default AutoLogin;
