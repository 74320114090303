import HelpCta from "../components/help-cta";
import MenuPublic from "../components/menu-public";

const Help = () => {
  return (
    <>
      <MenuPublic />
      <div className="help">
        <div className="page__header">
          <div className="container-md">
            <div className="row">
              <div className="col-12 ">
                <div className="page__header-text d-flex flex-column justify-content-center">
                  <h1 className="page__header-title">Hai bisogno di aiuto?</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page__content">
          <div className="container-md">
            <div className="row">
              <div className="col-12 ">
                <div className="pb-5">
                  Lorem ipsum dolor sit amet. Et rerum dolor sit molestias soluta aut maiores quia
                  et quos doloremque non omnis molestiae quo unde aliquid. Qui obcaecati labore ut
                  sint facere At ratione perferendis non deleniti nisi ut ipsa vitae aut reiciendis
                  nobis. Et quidem velit aut necessitatibus beatae ex nesciunt sapiente sit
                  voluptate galisum. Rem laudantium cumque non dignissimos velit in exercitationem
                  cumque. Ut voluptates enim ut sunt iure qui laudantium dolorem et iusto vitae. Qui
                  quisquam magnam qui quod nihil aut rerum autem eum eius ducimus. At recusandae
                  ullam cum iure molestiae et consequatur illum qui quia illum ea ipsum consequatur
                  qui nesciunt quia.
                </div>
                <div className="row">
                  <div className="col-md-4 offset-md-2">
                    <HelpCta
                      header={"Vai al sito del supporto"}
                      body={"Rem laudantium cumque non dignissimos velit in exercitationem."}
                      url="/"
                    />
                  </div>
                  <div className="col-md-4">
                    <HelpCta
                      header={"Scrivici"}
                      body={"Rem laudantium cumque non dignissimos velit in exercitationem."}
                      url="/"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Help;
