import classNames from "classnames";
import { useForm } from "react-hook-form";
import Icon from "../components/icon";
import { useConsultant, useAddConsultantContact } from "../hooks/useQueryCustom";
import { toast } from "react-toastify";
import Seo from "../components/seo";
import { useState } from "react";

const Consultant = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm({ mode: "onChange" });
  const { data: consultant, isLoading } = useConsultant();
  const addConsultantContact = useAddConsultantContact();
  const [isSuccessContact, setIsSuccessContact] = useState<Boolean>(false);

  const onSubmit = async (data: any) => {
    const messageResult = data.messageResult as string;
    try {
      const response = await addConsultantContact.mutateAsync({ messageResult });
      console.log(response);
      setIsSuccessContact(true);
    } catch (e) {
      toast.error("Si è verificato un errore");
    }

    /* await toast.promise(addConsultantContact.mutateAsync({ messageResult }), {
      pending: "Invio in corso",
      success: {
        render() {
          return "Operazione completata";
        },
        autoClose: false,
      },
      error: {
        render() {
          return "Si è verificato un errore";
        },
        autoClose: false,
      },
    }); */
  };

  return (
    <div className="contact-consultant">
      <Seo title="Contatta un consulente" />
      <div className="page__header page__header--private">
        <div className="container-md">
          <div className="row">
            <div className="col-12 ">
              <div className="page__header-text d-flex flex-column justify-content-center">
                <h1 className="page__header-title">Contatta un consulente</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="container-md">
          <div className="row">
            <div className="col-12 ">
              <h2 className="page__subtitle mb-3">Il tuo messaggio</h2>

              {isSuccessContact ? (
                <div>Richiesta inviata con successo!</div>
              ) : (
                <>
                  <p>
                    Puoi richiedere la versione digitale del tuo testo, indicando il titolo, oppure
                    chiedere altri materiali o altre informazioni sui nostri prodotti e servizi.
                  </p>

                  <form name="contact-consultant" onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-4">
                      <textarea
                        aria-label="il tuo messaggio"
                        className="form__textarea p-3"
                        placeholder="Inserisci qui il tuo messaggio"
                        {...register("messageResult", { required: true })}
                      ></textarea>
                      {errors && errors["messageResult"]?.type === "required" && (
                        <p className="form__error" role="alert">
                          Questo campo è richiesto
                        </p>
                      )}
                    </div>
                    <h3 className="mb-3">Il tuo consulente universitario:</h3>
                    {consultant && (
                      <>
                        <div className="consultant__name mb-2">
                          <strong>{consultant.fullName}</strong>
                        </div>
                        <div className="consultant__email mb-1">{consultant.email}</div>
                        {consultant.phoneNumber && (
                          <div className="consultant__email mb-1">
                            Tel. {consultant.phoneNumber}
                          </div>
                        )}
                      </>
                    )}
                    {!consultant && !isLoading && (
                      <div className="consultant__name mb-2">
                        <strong>
                          Per inviare una richiesta devi prima aver un consulente associato
                        </strong>
                      </div>
                    )}

                    <div className="form__divider mt-3"></div>
                    <div className="d-flex justify-content-end">
                      <button
                        type="submit"
                        disabled={isSubmitting || !consultant}
                        className={classNames(
                          "button button--tertiary button--w-xs-100 my-4",
                          { "button--tertiary": isValid },
                          { "button--disabled": !isValid || isSubmitting }
                        )}
                      >
                        <Icon name="paper-plane" width="26" height="22" /> Invia richiesta
                      </button>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="page__footer page__footer--private">
        <div className="container-md">
          <div className="row">
            <div className="col-12 ">
              <h2 className="page__header-title mb-3">Hai bisogno di aiuto?</h2>
              <div className="row">
                <div className="col-md-4">
                  <div id="embedded_video" className="page__video mb-4">
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube-nocookie.com/embed/5PaiyRw3eeQ"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="content">
                    <h3 className="mb-4">
                      <strong>Come accedere alle risorse digitali per i docenti</strong>
                    </h3>
                    <p>
                      Per maggiori informazioni su dove puoi trovare e come puoi utilizzare tutte le
                      risorse digitali per il docente abbinate ai libri di testo guarda il
                      videotutorial.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};
export default Consultant;
